import axios from 'axios';
import { baseUrl } from './BaseUrl';

// Define the type for identity types
export interface IdentityType {
    id: number;
    title: string;
    created_date: string;
  }
  
  export const getIdentityTypesApi = async (): Promise<IdentityType[]> => {
    console.log("getIdentityTypesApi function called");
    try {
      const response = await axios.get(`${baseUrl}/user-and-auth/user-account-identity-types`);
      return response.data.result as IdentityType[];
    } catch (error) {
      throw error;
    }
};
  