import React from "react";

import { Button, Img, Line, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";

const PlansPage = () => {
  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto w-full">
        <Sidebar1 activeItem="Plans" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-1 flex-col font-inter md:gap-10 gap-20 items-center justify-start md:px-5 w-full">
          <Header pageTitle="Plans" />
          <div className="flex flex-col gap-6 items-start justify-start w-auto sm:w-full mt-6 mb-6">
            <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col gap-4 items-center justify-center sm:px-5 px-6 py-8 rounded-[10px] w-full">
              <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                <Text
                  className="text-gray-900_01 text-lg w-full"
                  size="txtInterSemiBold18"
                >
                  Ella Briggs
                </Text>
                <Line className="bg-blue_gray-500 h-px w-full" />
              </div>
              <div className="flex flex-row gap-14 items-start justify-start w-full">
                <div className="flex flex-1 flex-col gap-[5px] items-start justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-xs w-[135px]"
                    size="txtInterRegular12Bluegray500"
                  >
                    Benefit (Over 6 months)
                  </Text>
                  <Text
                    className="text-base text-gray-900_01 w-auto"
                    size="txtInterRegular16Gray90001"
                  >
                    ₦100,000/Month
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-[5px] items-end justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-right text-xs w-[121px]"
                    size="txtInterRegular12Bluegray500"
                  >
                    Claims Eligibility Date
                  </Text>
                  <Text
                    className="text-base text-gray-900_01 w-auto"
                    size="txtInterRegular16Gray90001"
                  >
                    Jan 21, 2024
                  </Text>
                </div>
              </div>
              <Line className="bg-blue_gray-500 h-px w-full" />
              <div className="flex flex-row gap-[129px] items-start justify-start w-full">
                <div className="flex flex-1 flex-col gap-[5px] items-start justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-xs w-[57px]"
                    size="txtInterRegular12Bluegray500"
                  >
                    Policy No.
                  </Text>
                  <Text
                    className="text-base text-gray-900_01 w-auto"
                    size="txtInterRegular16Gray90001"
                  >
                    IP000246
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-[5px] items-end justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-right text-xs w-[58px]"
                    size="txtInterRegular12Bluegray500"
                  >
                    Start Date
                  </Text>
                  <Text
                    className="text-base text-gray_900_01 w-auto"
                    size="txtInterRegular16Gray90001"
                  >
                    Feb 12, 2024
                  </Text>
                </div>
              </div>
              <div className="flex flex-row gap-36 items-start justify-between w-full">
                <div className="flex flex-1 flex-col gap-[5px] items-start justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-xs w-[47px]"
                    size="txtInterRegular12Bluegray500"
                  >
                    Renewal
                  </Text>
                  <Text
                    className="text-base text-gray-900_01 w-auto"
                    size="txtInterRegular16Gray90001"
                  >
                    Annual
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-[5px] items-end justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-right text-xs w-[77px]"
                    size="txtInterRegular12Bluegray500"
                  >
                    Renewal Date
                  </Text>
                  <Text
                    className="text-base text-gray_900_01 w-auto"
                    size="txtInterRegular16Gray90001"
                  >
                    Mar 12, 2024
                  </Text>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-col items-start justify-start outline outline-[0.5px] outline-blue_gray-100 px-5 py-4 rounded w-full">
              <div className="flex flex-col gap-3 items-start justify-start w-full">
                <Text
                  className="text-base text-gray_900_01 w-full"
                  size="txtInterSemiBold16Gray90001"
                >
                  Your Policy
                </Text>
                <Text
                  className="leading-[145.00%] max-w-[370px] md:max-w-full text-base text-blue_gray-500"
                  size="txtInterRegular16Bluegray500"
                >
                  See what is convered and what your policy does not cover. You
                  can also demand to have a copy and we will send it to your
                  email
                </Text>
                <Button
                  className="cursor-pointer font-semibold text-center text-sm w-full"
                  shape="square"
                  color="deep_orange_50_01"
                  size="md"
                  variant="fill"
                >
                  Download Policy
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansPage;
