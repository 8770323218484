// DocumentsPage.js

import React from "react";
import { Button, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";

const DocumentsPage = () => {
  return (
    <>
      <div className="bg-gray-50 font-holtwoodonesc h-[1045px] mx-auto relative w-full">
        <Sidebar1 activeItem="Documents" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden inset-y-[0] justify-start left-[0] my-auto overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="absolute bottom-[0] flex flex-col font-inter gap-8 h-[1044px] md:h-auto items-center justify-start max-w-[1237px] md:px-5 right-[0] w-full">
          <Header pageTitle="Documents" />
          <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-start justify-start max-w-[1172px] px-5 py-6 rounded w-full">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="bg-white-A700 border border-gray-100 border-solid flex flex-col items-center justify-start p-6 sm:px-5 rounded-[10px] w-full">
                <div className="flex flex-col gap-1.5 items-start justify-center w-full">
                  <div className="flex flex-row gap-1.5 items-start justify-between w-full">
                    <Text
                      className="text-gray-900_01 text-xl tracking-[-0.40px] w-auto"
                      size="txtInterSemiBold20"
                    >
                      National ID
                    </Text>
                    <div className="flex flex-row gap-2.5 items-start justify-start w-auto">
                      <Button
                        className="cursor-pointer font-semibold min-w-[66px] text-center text-sm"
                        shape="square"
                        color="deep_orange_500"
                        size="md"
                        variant="outline"
                      >
                        View
                      </Button>
                      <Button
                        className="cursor-pointer font-semibold min-w-[82px] text-center text-sm"
                        shape="square"
                        color="blue_700"
                        size="md"
                        variant="fill"
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                  <Text
                    className="text-base text-blue_gray-500 w-auto"
                    size="txtInterRegular16Bluegray500"
                  >
                    Last updated: 16 Jan, 2024
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsPage;
