import React from "react";

import { Img, Text } from "../";

const TransactionHistoryContent1 = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="bg-white-A700 flex flex-col h-full items-center justify-start p-4 w-full">
          <div className="flex flex-col items-center justify-start w-full">
            <div className="bg-green-50 flex flex-col items-center justify-center px-3 py-0.5 rounded-[12px] w-auto">
              <div className="flex flex-col items-center justify-center w-auto">
                <Text
                  className="text-center text-green-800 text-sm w-auto"
                  size="txtInterMedium14Green800"
                >
                  {props?.approvedtext}
                </Text>
              </div>
            </div>
          </div>
        </div>
        {!!props?.separatorimage ? (
          <Img
            className="h-px w-full"
            alt="separator_One"
            src={props?.separatorimage}
          />
        ) : null}
      </div>
    </>
  );
};

TransactionHistoryContent1.defaultProps = { approvedtext: "Approved" };

export default TransactionHistoryContent1;
