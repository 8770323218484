import React from "react";

import { Img, Text } from "../";

const TransactionHistoryColumniconhome = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-row gap-3 items-start justify-start w-full">
          <Img className="h-5 w-5" alt="iconhome" src={props?.iconimage} />
          <Text
            className="flex-1 text-gray-900_01 text-sm w-auto"
            size="txtInterRegular14Gray90001"
          >
            {props?.text}
          </Text>
        </div>
      </div>
    </>
  );
};

TransactionHistoryColumniconhome.defaultProps = {
  iconimage: "images/img_iconhome_blue_gray_500.svg",
  text: "Home",
};

export default TransactionHistoryColumniconhome;
