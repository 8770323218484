import React, { useState, useEffect } from "react";
import { getUserAccountApi } from "../../Common/dataNew/GetUserAccount";

import { useNavigate } from "react-router-dom";

import { Button, Img, Input, Line, List, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";
import Breadcrumb from "../../components/BreadCrumb";

const SettingsPage = () => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user account data from the API and set the state
    const fetchUserAccountData = async () => {
        try {
            const userData = await getUserAccountApi();
            setFirstName(userData.first_name);
            setLastName(userData.surname);
            setEmail(userData.email);
            setPhoneNumber(userData.mobile_number);
        } catch (error) {
            console.error('Error fetching user account data:', error.message);
        }
    };

    fetchUserAccountData();  // Call the fetchUserAccountData function when the component mounts
  }, []);

  const breadcrumbItems = [
    { text: "Settings", nextText: "Application" },
  ];

  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto pb-[45px] w-full">
        <Sidebar1 activeItem="Settings" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-col font-inter gap-8 items-center justify-center md:px-5 w-full md:w-full">
          <Header pageTitle="Settings" />
          <div className="flex flex-col font-inter gap-10 items-start justify-start mb-[19px] ml-[100px] mt-auto md:px-5 w-full z-[1]">
            <Breadcrumb items={breadcrumbItems} />
            <div className="bg-white-A700 flex flex-col gap-6 items-start justify-start px-5 py-6 rounded w-[70%] md:w-full">
              <div className="border-b-2 border-blue_gray-100 border-solid flex flex-row gap-2.5 items-start justify-start w-full">
                <div className="flex flex-col items-center justify-center rounded-md w-auto">
                  <div
                    className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                    onClick={() => navigate("/settings")}
                  >
                    <div className="flex flex-col items-center justify-center w-auto">
                      <Text
                        className="text-base text-deep_orange-500 w-auto"
                        size="txtInterMedium16Deeporange500"
                      >
                        Profile
                      </Text>
                    </div>
                  </div>
                  <Line className="bg-deep_orange-500 h-0.5 w-full" />
                </div>
                <div className="flex flex-col items-center justify-center rounded-md w-auto">
                  <div
                    className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                    onClick={() => navigate("/settingsinsurance")}
                  >
                    <div className="flex flex-col items-center justify-center w-auto">
                      <Text
                        className="text-base text-gray-900_01 w-auto"
                        size="txtInterMedium16"
                      >
                        Insurance
                      </Text>
                    </div>
                  </div>
                  <Line className="bg-blue_gray-100 h-0.5 w-full" />
                </div>
                <div className="flex flex-col items-center justify-center rounded-md w-auto">
                  <div
                    className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                    onClick={() => navigate("/settingspayment")}
                  >
                    <div className="flex flex-col items-center justify-center w-auto">
                      <Text
                        className="text-base text-gray-900_01 w-auto"
                        size="txtInterMedium16"
                      >
                        Payment
                      </Text>
                    </div>
                  </div>
                  <Line className="bg-blue_gray-100 h-0.5 w-full" />
                </div>
              </div>
              <div className="flex flex-col gap-6 items-start justify-start w-full">
                <div className="flex flex-col gap-6 items-start justify-start w-full">
                  <div className="flex flex-col items-start justify-start w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <Text
                        className="text-base text-blue_gray-500 w-auto"
                        size="txtInterRegular16Bluegray500"
                      >
                        Profile Settings
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row gap-3 items-center justify-start w-auto">
                    <div className="flex flex-col h-[68px] items-center justify-start w-[68px]">
                      <Img
                        className="h-[68px] md:h-auto rounded-[50%] w-[68px]"
                        src="images/img_image_68x68.png"
                        alt="image_One"
                      />
                    </div>
                    <Button
                      className="cursor-pointer font-semibold text-center text-sm w-[114px]"
                      shape="square"
                      color="deep_orange_50_01"
                      size="md"
                      variant="fill"
                    >
                      Change Photo
                    </Button>
                  </div>
                  <div className="flex flex-col gap-4 items-start justify-start w-full">
                    <List
                      className="sm:flex-col flex-row gap-2.5 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
                      orientation="horizontal"
                    >
                      <div className="flex flex-1 flex-col items-start justify-start w-full">
                        <div className="flex flex-col gap-1 items-start justify-start w-full">
                          <Text
                            className="text-gray-900_01 text-sm w-full"
                            size="txtInterMedium14"
                          >
                            First Name
                          </Text>
                          <Input
                            name="column"
                            placeholder={firstName}
                            className="md:h-auto p-0 placeholder:text-gray-900_01 sm:h-auto text-gray-900_01 text-left text-sm w-full"
                            wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid p-2 rounded w-full"
                          ></Input>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col items-start justify-start w-full">
                        <div className="flex flex-col gap-1 items-start justify-start w-full">
                          <Text
                            className="text-gray-900_01 text-sm w-full"
                            size="txtInterMedium14"
                          >
                            Last Name
                          </Text>
                          <Input
                            name="column"
                            placeholder={lastName}
                            className="md:h-auto p-0 placeholder:text-gray-900_01 sm:h-auto text-gray-900_01 text-left text-sm w-full"
                            wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid p-2 rounded w-full"
                          ></Input>
                        </div>
                      </div>
                    </List>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <div className="flex flex-col gap-1 items-start justify-start w-full">
                        <Text
                          className="text-gray-900_01 text-sm w-full"
                          size="txtInterMedium14"
                        >
                          Email Address
                        </Text>
                        <Input
                          name="email"
                          placeholder={email}
                          className="md:h-auto p-0 placeholder:text-gray-900_01 sm:h-auto sm:pr-5 text-gray-900_01 text-left text-sm w-full"
                          wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid pl-3 pr-[35px] py-[9px] rounded-md w-full"
                        ></Input>
                      </div>
                      <Text
                        className="text-[13px] text-gray-700_01 w-full"
                        size="txtInterRegular13"
                      >
                        We’ll use this address if we need to contact you about
                        your account.
                      </Text>
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col gap-1 items-start justify-start w-full">
                        <Text
                          className="text-gray-900_01 text-sm w-full"
                          size="txtInterMedium14"
                        >
                          Phone Number
                        </Text>
                        <Input
                          name="mobileNo"
                          placeholder={phoneNumber}
                          className="md:h-auto p-0 placeholder:text-gray-900_01 sm:h-auto sm:pr-5 text-gray-900_01 text-left text-sm w-full"
                          wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid pl-3 pr-[35px] py-[9px] rounded-md w-full"
                        ></Input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-8 items-end justify-start w-full">
                  <Text
                    className="text-base text-blue_gray-500 w-auto"
                    size="txtInterRegular16Bluegray500"
                  >
                    Change Passwords
                  </Text>
                  <div className="flex flex-col gap-8 items-end justify-start w-full">
                    <List
                      className="flex flex-col gap-8 items-center w-full"
                      orientation="vertical"
                    >
                      <div className="flex flex-1 flex-col items-start justify-start my-0 w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <div className="flex flex-col gap-1 items-start justify-start w-full">
                            <Text
                              className="text-gray-900_01 text-sm w-full"
                              size="txtInterMedium14"
                            >
                              New Password
                            </Text>
                            <Input
                              name="columnmusic"
                              placeholder=""
                              className="p-0 w-full"
                              wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid flex md:h-auto p-2 rounded w-full"
                              suffix={
                                <div className="h-5 mr-1 flex-col justify-start items-center my-auto">
                                  <Img src="images/img_music.svg" alt="music" />
                                </div>
                              }
                            ></Input>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col items-end justify-start my-0 w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <div className="flex flex-col gap-1 items-start justify-start w-full">
                            <Text
                              className="text-gray-900_01 text-sm w-full"
                              size="txtInterMedium14"
                            >
                              Confirm Password
                            </Text>
                            <Input
                              name="columnmusic"
                              placeholder=""
                              className="p-0 w-full"
                              wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid flex md:h-auto p-2 rounded w-full"
                              suffix={
                                <div className="h-5 mr-1 my-auto">
                                  <Img src="images/img_music.svg" alt="music" />
                                </div>
                              }
                            ></Input>
                          </div>
                        </div>
                      </div>
                    </List>
                    <div className="flex flex-col items-center justify-center w-[454px] sm:w-full">
                      <Button
                        className="cursor-pointer font-semibold text-base text-center w-full"
                        shape="square"
                        color="deep_orange_700"
                        size="xl"
                        variant="fill"
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
