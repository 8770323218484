import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row, Image, Nav, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signupApi } from "../../Common/dataNew/SignUp";
import dummyUser from "../../assets/images/users/img_image.png";

import logolight from "../../assets/images/logo-light.png";
import authbackground from "../../assets/images/auth-bg.jpg";

const CreateAccount = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [alertVariant, setAlertVariant] = useState<"success" | "danger" | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      tab: "#phone",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },

    onSubmit: async (values) => {
      try {
        const response = await signupApi(values.email, values.password);
    
        const { message, status, result } = response;
    
        console.log(response);
        console.log("Status:", status);
        console.log("Message:", message);
    
        // Display alert
        setAlertMessage(message);
        setAlertVariant(status === 201 ? "success" : "danger");
    
        // Store information in localStorage
        if (status === 201) {
          localStorage.setItem("user_info", JSON.stringify(result));
          
          // Redirect to the sign-in page
          navigate("/auth-signin-basic");
        }
      } catch (error:any) {
        console.error("Signup error:", error.message);
    
        // Display error alert with dynamic message
        setAlertMessage(error.response ? error.response.data.message : "Signup failed. Please try again.");
        setAlertVariant("danger");
      }
    },        
  });

  const handleTogglePassword = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  return (
    <React.Fragment>
      <section className="position-relative min-vh-100 d-flex align-items-center justify-content-between">
        <Container fluid className="px-0">
          <div className="h-100 d-flex">
            {/* Fixed height for the first column */}
            <div
              className="text-white p-md-5 d-flex flex-column justify-content-between m-3"
              style={{ 
                width: '50%', 
                borderRadius: "2em",
                backgroundImage: `url(${authbackground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div>
                <Image src={logolight} className="card-logo card-logo-dark" alt="logo dark" style={{ height: '16px' }} />
                <h3 className="text-white mt-4 text-wrap" style={{ fontSize: "3rem"}}>
                  Your gateway to hassle-free insurance
                </h3>

                <p className="text-muted fs-14 mt-3 lh-base" style={{ color: "#E4DBDB" }}>
                  Say goodbye to complex forms and hello to simplicity. We've
                  made it easy—just use your airtime to get started. Protect
                  what matters most without the fuss.
                </p>
              </div>
              <div
                className="p-4"
                style={{ backgroundColor: "#3E3838", borderRadius: "2em" }}
              >
                <p className=" fs-13 lh-base" style={{ color: "#F0E6E6" }}>
                  EVAS has transformed the way I view insurance. The sheer
                  range of options and the easy-to-understand steps have been
                  game-changers. It's like having insurance at the palm of your
                  hands without complicated processes or involving third parties.
                </p>

                <div className="d-flex align-items-center mt-3">
                  <Image
                    className="rounded-circle header-profile-user me-3"
                    src={dummyUser}
                    alt="Header Avatar"
                  />
                  <div>
                    <p className="mb-0 fw-bold fs-13 mb-1">Ella Briggs</p>
                    <p className="text-muted mb-0 fs-11">Fashion Designer</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Scrollable second column */}
            <div
              className="auth-page-wrapper align-items-center justify-content-center px-md-5"
              style={{ overflowY: 'auto', height: '100vh', width: '50%' }}
            >
              <div
                className="auth-card mx-lg-3 "
                style={{ minWidth: "500px" }}
              >
                <Card className="border-0 mb-0">
                  <Card.Body>
                    <h2 className="d mb-1">Create your account</h2>
                    <p className="text-muted">
                      Let’s begin your journey of accessing easy insurance
                      options. Use your phone number or email address to sign up.
                    </p>

                    <Nav
                      variant="tabs"
                      activeKey={formik.values.tab}
                      onSelect={(key) => formik.setFieldValue("tab", key)}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="#phone">Phone Number</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#email">Email</Nav.Link>
                      </Nav.Item>
                    </Nav>

                    {/* Display Bootstrap Alert */}
                    {alertMessage && alertVariant !== null && (
                      <Alert variant={alertVariant} className="mt-3" onClose={() => setAlertMessage(null)} dismissible>
                        {alertMessage}
                      </Alert>
                    )}


                    <Form action="#" onSubmit={formik.handleSubmit}>
                      {formik.values.tab === "#phone" && (
                        <div className="mb-3 mt-3">
                          <Form.Label htmlFor="username">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            id="username"
                            placeholder="Enter phone number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.phone && formik.touched.phone ? (
                            <span className="text-danger">
                              {formik.errors.phone}
                            </span>
                          ) : null}
                        </div>
                      )}

                      {formik.values.tab === "#email" && (
                        <React.Fragment>
                          <div className="mb-3 mt-3">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Enter email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.email && formik.touched.email ? (
                              <span className="text-danger">
                                {formik.errors.email}
                              </span>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <Form.Control
                              type={passwordVisible ? "text" : "password"}
                              name="password"
                              id="password"
                              placeholder="Enter password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.password && formik.touched.password ? (
                              <span className="text-danger">
                                {formik.errors.password}
                              </span>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Form.Label htmlFor="confirmPassword">
                              Confirm Password
                            </Form.Label>
                            <Form.Control
                              type={passwordVisible ? "text" : "password"}
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder="Confirm password"
                              value={formik.values.confirmPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                              <span className="text-danger">
                                {formik.errors.confirmPassword}
                              </span>
                            ) : null}
                          </div>
                        </React.Fragment>
                      )}

                      <div className="mt-4">
                        <Button
                          className="w-100 custom-primary-btn"
                          type="submit"
                          style={{
                            backgroundColor: "#EB5017",
                            color: "#fff",
                            borderColor: "#EB5017",
                          }}
                        >
                          Create Account
                        </Button>
                      </div>
                    </Form>

                    <div className="text-center mt-3">
                      <p>
                        Do you have an account?{" "}
                        <Link
                          to="/"
                          className="fw-semibold custom-text-primary text-decoration-underline"
                        >
                          Sign In
                        </Link>{""}
                      </p>
                    </div>

                    {formik.values.tab === "#email" && (
                      <div className="pt-2 text-center">
                        <div className="pt-2 hstack gap-2 justify-content-center">
                          <Button className="btn btn-soft-danger btn-icon">
                            <i className="ri-google-fill fs-16" />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CreateAccount;
