import React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Input, Line, List, SelectBox, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";

import TransactionHistoryColumniconhome from "../../components/TransactionHistoryColumniconhome";
import TransactionHistoryContent from "../../components/TransactionHistoryContent";
import TransactionHistoryHeader from "../../components/TransactionHistoryHeader";
import TransactionHistoryLeading from "../../components/TransactionHistoryLeading";

import { CloseSVG } from "../../assets/images";

const defaultbuttonOptionsList = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

const TransactionhistoryOnePage = () => {
  const navigate = useNavigate();

  const [inputframevalue, setInputframevalue] = React.useState("");
  const transactionHistoryContentPropList = [
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    {},
  ];
  const transactionHistoryContentPropList1 = [
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    {},
  ];
  const transactionHistoryContentPropList2 = [
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    { separatorone: "images/img_separator.svg" },
    {},
  ];

  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto pb-[47px] w-full">
        <Sidebar1 className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-col font-inter gap-8 items-center justify-center md:px-5 w-auto md:w-full">
          <div className="bg-white-A700 flex flex-row md:gap-10 items-center justify-between max-w-[1237px] sm:px-5 px-8 py-6 w-full">
            <Text
              className="text-2xl md:text-[22px] text-gray-900_01 sm:text-xl tracking-[-0.48px] w-auto"
              size="txtInterBold24"
            >
              Transaction History
            </Text>
            <div className="flex flex-row font-assistant gap-2 items-center justify-start w-auto">
              <div className="flex flex-row gap-2 items-center justify-start w-auto">
                <div className="flex flex-col h-9 items-center justify-start w-9">
                  <Img
                    className="h-9 md:h-auto rounded-[50%] w-9"
                    src="images/img_image_36x36.png"
                    alt="image"
                  />
                </div>
                <Text
                  className="text-base text-deep_orange-500 w-auto"
                  size="txtAssistantRomanBold16"
                >
                  Ella
                </Text>
              </div>
              <Img
                className="h-[17px] w-4"
                src="images/img_arrowdown_deep_orange_500.svg"
                alt="arrowdown"
              />
            </div>
          </div>
          <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col gap-6 items-start justify-start max-w-[1172px] px-5 py-6 rounded w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex flex-col items-center justify-between w-full">
                <div className="flex flex-row gap-3 items-start justify-start w-full">
                  <Button
                    className="common-pointer bg-transparent cursor-pointer min-w-[71px] text-base text-blue_gray-500 text-center"
                    onClick={() => navigate("/transactionhistory")}
                    size="md"
                  >
                    Claims
                  </Button>
                  <Button
                    className="cursor-pointer font-semibold min-w-[128px] text-base text-center"
                    shape="square"
                    color="deep_orange_500"
                    size="md"
                    variant="outline"
                  >
                    Subscriptions
                  </Button>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-col gap-px items-start justify-start rounded-[10px] w-full">
              <div className="flex md:flex-col flex-row gap-3 items-start justify-start p-4 w-full">
                <div className="flex flex-1 md:flex-col flex-row gap-3 h-[39px] md:h-auto items-start justify-start w-full">
                  <div className="flex flex-1 flex-col items-start justify-center w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col items-start justify-start w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <Input
                            name="inputframe"
                            placeholder="Search here..."
                            value={inputframevalue}
                            onChange={(e) => setInputframevalue(e)}
                            className="p-0 placeholder:text-blue_gray-500 sm:pr-5 text-base text-blue_gray-500 text-left w-full"
                            wrapClassName="border border-blue_gray-100 border-solid flex pl-3 pr-[35px] py-[9px] rounded-md shadow-bs w-full"
                            prefix={
                              <Img
                                className="mt-px mb-auto cursor-pointer h-5 mr-2"
                                src="images/img_search_blue_gray_500.svg"
                                alt="search"
                              />
                            }
                            suffix={
                              <CloseSVG
                                fillColor="#667185"
                                className="cursor-pointer h-5 my-auto"
                                onClick={() => setInputframevalue("")}
                                style={{
                                  visibility:
                                    inputframevalue?.length <= 0
                                      ? "hidden"
                                      : "visible",
                                }}
                                height={20}
                                width={20}
                                viewBox="0 0 20 20"
                              />
                            }
                          ></Input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="border border-blue_gray-100 border-solid cursor-pointer flex items-center justify-center min-w-[90px] rounded-lg shadow-bs4"
                    leftIcon={
                      <Img
                        className="h-5 mt-px mr-2"
                        src="images/img_buttonicon_gray_900_01.svg"
                        alt="button-icon"
                      />
                    }
                    color="white_A700"
                    size="md"
                    variant="fill"
                  >
                    <div className="!text-gray-900_01 text-base text-center">
                      Filter
                    </div>
                  </Button>
                </div>
                <div className="flex sm:flex-1 flex-col items-end justify-center w-auto sm:w-full">
                  <SelectBox
                    className="border border-blue_gray-100 border-solid rounded-md shadow-bs4 text-base text-center w-full"
                    placeholderClassName="text-gray-900_01"
                    indicator={
                      <Img
                        className="h-5 w-5"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isSearchable={false}
                    placeholder="Select dates"
                    getOptionLabel={(e) => (
                      <div className="flex items-center">
                        <Img
                          className="h-5 mr-1 w-5"
                          src="images/img_icon_calendar.svg"
                          alt="icon / calendar"
                        />
                        <span>{e.label}</span>
                      </div>
                    )}
                    name="defaultbutton"
                    isMulti={false}
                    options={defaultbuttonOptionsList}
                    shape="round"
                    color="white_A700"
                    size="xs"
                    variant="fill"
                  />
                </div>
              </div>
              <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
                <div className="flex flex-1 flex-col items-center justify-start w-full">
                  <List
                    className="flex flex-col items-start w-full"
                    orientation="vertical"
                  >
                    <TransactionHistoryHeader
                      className="flex flex-1 flex-col h-[45px] md:h-auto items-start justify-start my-0 w-full"
                      descriptiontext="Subcription"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-start my-0 w-full"
                      claimtext="Airtime"
                      separatorimage="images/img_separator.svg"
                    />
                  </List>
                </div>
                <div className="flex flex-col items-start justify-start w-[200px]">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full"
                    descriptiontext="Network"
                  />
                  <div className="flex flex-col items-start justify-start w-full">
                    <List
                      className="flex flex-col gap-px items-center w-full"
                      orientation="vertical"
                    >
                      {transactionHistoryContentPropList.map((props, index) => (
                        <React.Fragment
                          key={`TransactionHistoryContent${index}`}
                        >
                          <TransactionHistoryContent
                            className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-center w-full"
                            supportivetext="MTN"
                            {...props}
                          />
                        </React.Fragment>
                      ))}
                    </List>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-[200px]">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full"
                    descriptiontext="ID"
                  />
                  <div className="flex flex-col items-start justify-start w-full">
                    <List
                      className="flex flex-col gap-px items-center w-full"
                      orientation="vertical"
                    >
                      {transactionHistoryContentPropList1.map(
                        (props, index) => (
                          <React.Fragment
                            key={`TransactionHistoryContent${index}`}
                          >
                            <TransactionHistoryContent
                              className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-center w-full"
                              supportivetext="#28373"
                              {...props}
                            />
                          </React.Fragment>
                        )
                      )}
                    </List>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-[200px]">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full"
                    descriptiontext="Amount"
                  />
                  <div className="flex flex-col items-start justify-start w-full">
                    <List
                      className="flex flex-col gap-px items-center w-full"
                      orientation="vertical"
                    >
                      {transactionHistoryContentPropList2.map(
                        (props, index) => (
                          <React.Fragment
                            key={`TransactionHistoryContent${index}`}
                          >
                            <TransactionHistoryContent
                              className="flex flex-1 flex-col h-[53px] md:h-auto items-start justify-center w-full"
                              supportivetext="#500"
                              {...props}
                            />
                          </React.Fragment>
                        )
                      )}
                    </List>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-56">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full"
                    descriptiontext="Date"
                  />
                  <div className="flex flex-col items-start justify-start w-full">
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full" />
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 flex flex-col items-center justify-center outline outline-blue_gray-100 px-4 w-full">
                <div className="bg-white-A700 flex flex-col items-center justify-end py-4 w-full">
                  <div className="flex sm:flex-col flex-row gap-6 items-center justify-end w-auto">
                    <Text
                      className="text-base text-center text-gray-900_01 w-auto"
                      size="txtInterMedium16"
                    >
                      Page 1 of 30
                    </Text>
                    <div className="flex flex-row gap-1 items-center justify-center w-auto">
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        1
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        2
                      </Button>
                      <Button className="bg-white-A700 border border-deep_orange-500 border-solid cursor-pointer h-6 py-[3px] rounded-md text-black-900 text-center text-sm w-6">
                        3
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        4
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        5
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        6
                      </Button>
                    </div>
                    <div className="flex flex-row gap-4 items-center justify-center w-auto">
                      <div className="flex flex-col items-start justify-start w-auto">
                        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-center justify-center px-3 py-2 rounded-lg shadow-bs w-auto">
                          <div className="flex flex-col items-center justify-center w-5">
                            <Img
                              className="h-5 w-5"
                              src="images/img_arrowleft.svg"
                              alt="arrowleft"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start w-auto">
                        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-center justify-center px-3 py-2 rounded-lg shadow-bs w-auto">
                          <div className="flex flex-col items-center justify-center w-5">
                            <Img
                              className="h-5 w-5"
                              src="images/img_arrowleft.svg"
                              alt="arrowright"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionhistoryOnePage;
