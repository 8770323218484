import axios from 'axios';
import { baseUrl } from './BaseUrl';

// Extract user.id from the user information in local storage
export const getUserIdFromLocalStorage = () => {
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;
    return user ? user.user.id : null;
};

export const addBeneficiaryDetailsApi = async (
  lastName: string,
  firstName: string,
  middleName: string,
  email: string,
  mobileNumber: string,
  relationship: string,
  dateOfBirth: string,
  cifId: string,
  planId: string
) => {
  try {
    // Get user ID and user from local storage
    const userId = getUserIdFromLocalStorage();
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;

    // Ensure that user ID and user are available
    if (userId && user) {
      const response = await axios.post(
        `${baseUrl}/user-and-auth/user-account-add-beneficiary/${userId}`,
        {
          last_name: lastName,
          first_name: firstName,
          middle_name: middleName,
          email: email,
          mobile_number: mobileNumber,
          relationship: relationship,
          date_of_birth: dateOfBirth,
          cif_id: cifId,
          plan_id: planId,
        },
        // Include the JWT token in the request headers
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      return response.data;
    } else {
      throw new Error('User ID or user information is missing in local storage.');
    }
  } catch (error: any) {
    console.error('Error adding beneficiary details:', error.message);
    throw error;
  }
};
