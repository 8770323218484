import axios from 'axios';
import { baseUrl } from './BaseUrl';

// Extract user.id from the user information in local storage
export const getUserIdFromLocalStorage = () => {
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;
    return user ? user.user.id : null;
};

export const updateUserAccountApi = async (state: string, lga: string) => {
    try {
        // Get user ID and user from local storage
        const userId = getUserIdFromLocalStorage();
        const userString = localStorage.getItem('user');
        const user = userString ? JSON.parse(userString) : null;

        // Ensure that user ID and user are available
        if (userId && user) {
            const response = await axios.put(
                `${baseUrl}/user-and-auth/user-account-update-info/${userId}`, 
                {
                    state: state,
                    lga: lga,
                },
                // Include the JWT token in the request headers
                {
                    headers: {
                        Authorization: `Bearer ${user.jwt}`,
                    },
                }
            );
            return response.data;
        } else {
            throw new Error('User ID or user information is missing in local storage.');
        }
    } catch (error:any) {
        console.error('Error updating user account:', error.message);
        throw error;
    }
};
