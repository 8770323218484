import React from 'react';
import { ProSidebarProvider } from "react-pro-sidebar";

import "./assets/scss/themes.scss";

//imoprt Route
import Route from './Routes/index';

function App() {
  return (
    <ProSidebarProvider>
      <Route />
    </ProSidebarProvider>
  );
}

export default App;
