import axios from 'axios';
import { baseUrl } from './BaseUrl';

// Extract user.id from the user information in local storage
export const getUserIdFromLocalStorage = () => {
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;
    return user ? user.user.id : null;
};

export const getUserAccountApi = async () => {
  try {
    // Get user ID and user from local storage
    const userId = getUserIdFromLocalStorage();
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;

    // Ensure that user ID and user are available
    if (userId && user) {
      const response = await axios.get(
        `${baseUrl}/user-and-auth/user-account-user/${userId}`,
        // Include the JWT token in the request headers
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      );
      return response.data.result;
    } else {
      throw new Error('User ID or user information is missing in local storage.');
    }
  } catch (error:any) {
    console.error('Error retrieving user account details:', error.message);
    throw error;
  }
};
