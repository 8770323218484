import React from "react";

import { Img, Text } from "../";

const TransactionHistoryHeader = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full">
          <div className="bg-white-A700 flex flex-col h-11 md:h-auto items-center justify-start px-4 py-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-base text-blue_gray-500 w-auto"
                size="txtInterMedium16Bluegray500"
              >
                {props?.descriptiontext}
              </Text>
            </div>
          </div>
          <Img
            className="h-px w-full"
            src="images/img_separator.svg"
            alt="separator"
          />
        </div>
      </div>
    </>
  );
};

TransactionHistoryHeader.defaultProps = { descriptiontext: "Description" };

export default TransactionHistoryHeader;
