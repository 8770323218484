import React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Input, Line, List, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";
import Breadcrumb from "../../components/BreadCrumb";

const SettingsPage = () => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    { text: "Settings", nextText: "Payment" },
  ];

  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto pb-[45px] w-full">
        <Sidebar1 activeItem="Settings" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-col font-inter gap-8 items-center justify-center md:px-5 w-full md:w-full">
          <Header pageTitle="Settings" />
          <div className="flex flex-col font-inter gap-10 items-start justify-start mb-[19px] ml-[100px] mt-auto md:px-5 w-full z-[1]">
            <Breadcrumb items={breadcrumbItems} />
            <div className="bg-white-A700 flex flex-col gap-6 items-start justify-start px-5 py-6 w-full">
              <div className="border-b-2 border-blue_gray-100 border-solid flex flex-row gap-2.5 items-start justify-start w-full">
                <div
                  className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                  onClick={() => navigate("/settings")}
                >
                  <div className="flex flex-col items-center justify-center w-auto">
                    <Text
                      className="text-base text-gray-900_01 w-auto"
                      size="txtInterMedium16"
                    >
                      Profile
                    </Text>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center rounded-md w-auto">
                  <div
                    className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                    onClick={() => navigate("/settingsinsurance")}
                  >
                    <div className="flex flex-col items-center justify-center w-auto">
                      <Text
                        className="text-base text-gray-900_01 w-auto"
                        size="txtInterMedium16"
                      >
                        Insurance
                      </Text>
                    </div>
                  </div>
                  <Line className="bg-blue_gray-100 h-0.5 w-full" />
                </div>
                <div className="flex flex-col items-center justify-center rounded-md w-auto">
                  <div
                    className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                    onClick={() => navigate("/settingspayment")}
                  >
                    <div className="flex flex-col items-center justify-center w-auto">
                      <Text
                        className="text-base text-deep_orange-500 w-auto"
                        size="txtInterMedium16Deeporange500"
                      >
                        Payment
                      </Text>
                    </div>
                  </div>
                  <Line className="bg-deep_orange-500 h-0.5 w-full" />
                </div>
              </div>
              <div className="flex flex-col gap-6 items-start justify-start w-full">
                <div className="flex flex-col items-start justify-start w-full">
                  <div className="flex flex-col items-start justify-start w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <Text
                        className="text-base text-blue_gray-500 w-auto"
                        size="txtInterRegular16Bluegray500"
                      >
                        Payment Cards
                      </Text>
                    </div>
                  </div>
                </div>
                <List
                  className="sm:flex-col flex-row gap-6 grid md:grid-cols-1 grid-cols-2 justify-start w-auto md:w-full"
                  orientation="horizontal"
                >
                  <div className="bg-green-100 flex flex-col gap-[45px] items-start justify-start sm:px-5 px-[27px] py-8 rounded-[20px] w-80">
                    <div className="relative w-full">
                      <div className="flex flex-col items-center justify-between mb-3.5 mt-auto w-[266px]">
                        <div className="flex flex-col items-center justify-between w-full">
                          <Img
                            className="h-6 md:h-auto object-cover w-6"
                            src="images/img_image4.png"
                            alt="imageFour"
                          />
                        </div>
                      </div>
                      <Text
                        className="absolute bg-blue-700 h-full inset-y-[0] justify-center my-auto pb-[23px] sm:pl-5 pl-[25px] pr-[5px] pt-1.5 right-[0] rotate-[-46deg] text-center text-white-A700 text-xs w-[66px]"
                        size="txtInterRegular12WhiteA700"
                      >
                        Default
                      </Text>
                    </div>
                    <div className="flex flex-row gap-[45px] items-end justify-end w-full">
                      <div className="flex flex-1 flex-col gap-3.5 items-start justify-start w-full">
                        <Text
                          className="text-black-900 text-lg w-auto"
                          size="txtInterSemiBold18Black900"
                        >
                          Ella
                        </Text>
                        <div className="flex flex-col items-center justify-start">
                          <Text
                            className="text-base text-black-900"
                            size="txtInterRegular16Black900"
                          >
                            081 234 567 890
                          </Text>
                        </div>
                      </div>
                      <Img
                        className="h-6 w-6"
                        src="images/img_iconbin.svg"
                        alt="iconbin"
                      />
                    </div>
                  </div>
                  <div className="bg-orange-100 flex flex-col gap-[45px] items-start justify-start sm:px-5 px-[27px] py-8 rounded-[20px] w-80">
                    <div className="relative w-full">
                      <div className="flex flex-col items-center justify-between mb-3.5 mt-auto w-[266px]">
                        <Img
                          className="h-6 w-full"
                          src="images/img_group718.svg"
                          alt="group718"
                        />
                      </div>
                      <Text
                        className="absolute bg-blue_gray-500 h-full inset-y-[0] justify-center my-auto pb-5 sm:pl-5 pl-[22px] pr-0.5 pt-[3px] right-[0] rotate-[-46deg] text-center text-white-A700 text-xs w-[66px]"
                        size="txtInterRegular12WhiteA700"
                      >
                        Disabled
                      </Text>
                    </div>
                    <div className="flex flex-row gap-[45px] items-end justify-end w-full">
                      <div className="flex flex-1 flex-col gap-3.5 items-start justify-start w-full">
                        <Text
                          className="text-black-900 text-lg w-auto"
                          size="txtInterSemiBold18Black900"
                        >
                          Ella
                        </Text>
                        <div className="flex flex-col items-center justify-start">
                          <Text
                            className="text-base text-black-900"
                            size="txtInterRegular16Black900"
                          >
                            **** **** **** 1234
                          </Text>
                        </div>
                      </div>
                      <Img
                        className="h-6 w-6"
                        src="images/img_iconbin.svg"
                        alt="iconbin"
                      />
                    </div>
                  </div>
                </List>
                <Button
                  className="cursor-pointer font-semibold text-base text-center w-[454px]"
                  shape="square"
                  color="deep_orange_700"
                  size="xl"
                  variant="fill"
                >
                  Add New Payment method
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
