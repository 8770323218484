import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container, Card } from "react-bootstrap";
import { getNigerianStatesApi } from "../../Common/dataNew/NigerianStates";
import { getNigerianLGAsApi } from "../../Common/dataNew/NigerianLGAs";
import { updateUserAccountApi } from "../../Common/dataNew/UpdateUserAccountLocation"; // Import the correct path
import { useNavigate } from "react-router-dom";

const LocationDetails = () => {
    const [selectedState, setSelectedState] = useState<string>("");
    const [selectedLGA, setSelectedLGA] = useState<string>("");
    const [nigerianStates, setNigerianStates] = useState<string[]>([]);
    const [nigerianLGAs, setNigerianLGAs] = useState<string[]>([]);
    const [isFormValid, setIsFormValid] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const statesResponse = await getNigerianStatesApi();
                setNigerianStates(statesResponse.result);
            } catch (error) {
                console.error("Error fetching Nigerian States:", error);
            }
        };

        fetchStates();
    }, []);

    useEffect(() => {
        // Check if the required fields are filled
        setIsFormValid(selectedState !== "" && selectedLGA !== "");
    }, [selectedState, selectedLGA]);

    const handleStateChange = async (state: string) => {
        setSelectedState(state);

        try {
            const lgasResponse = await getNigerianLGAsApi(state);
            setNigerianLGAs(lgasResponse.result);
        } catch (error) {
            console.error("Error fetching Nigerian LGAs:", error);
        }
    };

    const handleLGAChange = (lga: string) => {
        setSelectedLGA(lga);
    };

    const handleContinue = async () => {
        try {
            await updateUserAccountApi(selectedState, selectedLGA);
            console.log("Update User Account successful!");
            navigate("/personaldetails");

        } catch (error) {
            console.error("Error updating user account:", error);
            // Handle errors, display messages, or perform other actions
        }
    };

    return (
        <div className="auth-page-wrapper">
            <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
                <div className="text-center">
                    <Row className="mb-3">
                        <Col className="d-flex align-items-center">
                            <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                                <span className="font-weight-bold">1</span>
                            </div>
                            <p className="mb-0">Plan Details <i className="bi bi-chevron-right"></i></p>
                        </Col>

                        <Col className="d-flex align-items-center">
                            <div className="rounded-circle d-flex align-items-center justify-content-center custom-bg-primary" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                                <span className="font-weight-bold text-white">2</span>
                            </div>
                            <p className="mb-0">Location Details <i className="bi bi-chevron-right"></i></p>
                        </Col>

                        <Col className="d-flex align-items-center">
                            <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                                <span className="font-weight-bold">3</span>
                            </div>
                            <p className="mb-0">Personal Details <i className="bi bi-chevron-right"></i></p>
                        </Col>
                    </Row>

                    <h2 className="mb-2 mt-5">Select a hospital close to your location</h2>
                    <p className="text-muted">This can be changed later from your settings</p>
                    <p className="text-primary">You can also walk into other hospitals and make use of your insurance policy </p>
                </div>

                <Row className="my-5">
                    <Col>
                        <Form.Group>
                            <Form.Label>Select State</Form.Label>
                            <select
                                className="form-select"
                                id="choices-state-input"
                                name="choices-state-input"
                                onChange={(e) => handleStateChange(e.target.value)}
                                value={selectedState}
                            >
                                <option value="">-- Select State --</option>
                                {nigerianStates.map((state) => (
                                    <option key={state} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Select LGA</Form.Label>
                            <select
                                className="form-select"
                                id="choices-lga-input"
                                name="choices-lga-input"
                                onChange={(e) => handleLGAChange(e.target.value)}
                                value={selectedLGA}
                            >
                                <option value="">-- Select LGA --</option>
                                {nigerianLGAs.map((lga) => (
                                    <option key={lga} value={lga}>
                                        {lga}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                    </Col>
                </Row>

                {/* Commented-out section */}
                {/*
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Unity Clinics</Card.Title>
                                <Card.Text>
                                    Plot D1, Phase 2, Gado Nasco Way, Kubwa
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Unity Clinics</Card.Title>
                                <Card.Text>
                                    Plot D1, Phase 2, Gado Nasco Way, Kubwa
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Unity Clinics</Card.Title>
                                <Card.Text>
                                    Plot D1, Phase 2, Gado Nasco Way, Kubwa
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                */}
            </Container>
            <div className="text-center">
                <div className="fixed-bottom w-100 py-3 bg-white border-top ">
                    <Button
                        className={`btn w-100 ${isFormValid ? "custom-primary-button" : "btn-light"}`}
                        onClick={handleContinue}
                        disabled={!isFormValid}
                        style={{ 
                            maxWidth: "800px",
                            backgroundColor: isFormValid ? "#EB5017" : "btn-light", 
                            color: isFormValid ? "#fff" : "btn-light", 
                            borderColor: isFormValid ? "#EB5017" : "btn-light", 
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LocationDetails;
