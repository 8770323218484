import React, { useState, ChangeEvent, useRef } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const VerifyNin = () => {
  const [verificationCode, setVerificationCode] = useState(Array(4).fill(""));
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(4).fill(null));

  const navigate = useNavigate();

  const handleVerificationCodeChange = (index: number, value: string) => {
    // Allow only numbers in the verification code field
    const enteredValue = value.replace(/\D/g, '');

    // Update the verification code array with the new value
    const updatedVerificationCode = [...verificationCode];
    updatedVerificationCode[index] = enteredValue;
    setVerificationCode(updatedVerificationCode);

    // Move focus to the next input field
    if (index < inputRefs.current.length - 1 && enteredValue !== "") {
      inputRefs.current[index + 1]?.focus();
    }

    // Move focus to the previous input field when deleting
    if (index > 0 && enteredValue === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleContinue = () => {
    // Add logic to handle continue button click based on form inputs
    console.log("Verification Code:", verificationCode);

    navigate("/personaldetailsconfirmation");
  };

  return (
    <div className="auth-page-wrapper" style={{ minHeight: "100vh" }}>
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="text-center">
          {/* New Row with 3 Columns */}
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">1</span>
              </div>
              <p className="mb-0">ID <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center custom-bg-primary" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold text-white">2</span>
              </div>
              <p className="mb-0">Verification <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">3</span>
              </div>
              <p className="mb-0">Personal Details <i className="bi bi-chevron-right"></i></p>
            </Col>
          </Row>

          <h2 className="mb-2 mt-5">Verify your NIN</h2>
          <p>Please enter the 4-digit code sent to your phone number</p>
          <p>+234 1234 567 736.</p>
        </div>

        <Form>
          <Form.Group className="mb-3 d-flex justify-content-center">
            {verificationCode.map((digit, index) => (
              <Form.Control
                key={index}
                type="text"
                placeholder="-"
                maxLength={1}
                value={digit}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleVerificationCodeChange(index, e.target.value)
                }
                className="mx-1 text-center verification-input"
                ref={(ref: HTMLInputElement | null) => (inputRefs.current[index] = ref)}
              />
            ))}
          </Form.Group>
        </Form>
      </Container>
      <div className="text-center">
        <div className="fixed-bottom w-100 py-3 bg-white border-top ">
          <Button variant="light" className="btn btn-light w-100" onClick={handleContinue} style={{ maxWidth: "800px" }}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyNin;
