import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container, Alert } from "react-bootstrap";
import { addBeneficiaryDetailsApi } from "../../Common/dataNew/AddBeneficiaryDetails";
import { getBeneficiaryRelationshipsApi, BeneficiaryRelationship } from "../../Common/dataNew/GetBeneficiaryRelationship";
import { useNavigate } from "react-router-dom";

const BeneficiaryDetails = () => {
  // State variables for form inputs
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [relationship, setRelationship] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [beneficiaryRelationships, setBeneficiaryRelationships] = useState<BeneficiaryRelationship[]>([]);
  const [allFieldsComplete, setAllFieldsComplete] = useState<boolean>(false);

  const navigate = useNavigate();

  // Effect to check whether all fields are complete
  useEffect(() => {
    setAllFieldsComplete(
      firstName !== "" && lastName !== "" && email !== "" && relationship !== "" && phoneNumber !== ""
    );
  }, [firstName, lastName, email, relationship, phoneNumber]);

  // Function to fetch beneficiary relationships
  const fetchBeneficiaryRelationships = async () => {
    try {
      const relationships = await getBeneficiaryRelationshipsApi();
      setBeneficiaryRelationships(relationships);
    } catch (error:any) {
      console.error('Error fetching beneficiary relationships:', error.message);
      // Handle error as needed
    }
  };

  // useEffect to fetch beneficiary relationships when the component mounts
  useEffect(() => {
    fetchBeneficiaryRelationships();
  }, []);

  // Function to handle first name input
  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };

  // Function to handle last name input
  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  // Function to handle email input
  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  // Function to handle relationship selection
  const handleRelationshipChange = (value: string) => {
    setRelationship(value);
  };

  // Function to handle phone number input
  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleContinue = async () => {
    // Check if all fields are complete before triggering an action
    if (allFieldsComplete) {
      console.log("Form Values:", {
        firstName,
        lastName,
        email,
        relationship,
        phoneNumber,
      });

      // Call the API function to add beneficiary details
      try {
        await addBeneficiaryDetailsApi(
          lastName,
          firstName,
          "", // middleName can be added if available in the form
          email,
          phoneNumber,
          relationship,
          "", // dateOfBirth is not available in the form
          "", // cifId can be added if available in the form
          ""  // planId can be added if available in the form
        );

        // Add logic to handle continue button click based on form inputs
        console.log("Beneficiary Details added successfully!");

        navigate("/plandetails");
      } catch (error:any) {
        console.error('Error adding beneficiary details:', error.message);

        // Set the error state to display the error message
        setError(error.message);
      }
    }
  };
  

  return (
    <div className="auth-page-wrapper" style={{ minHeight: "100vh" }}>
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="text-center">
          {/* New Row with 3 Columns */}
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">1</span>
              </div>
              <p className="mb-0">Plan Details <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark " style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">2</span>
              </div>
              <p className="mb-0">Location Details <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center custom-bg-primary" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold text-white">3</span>
              </div>
              <p className="mb-0">Beneficiary Details <i className="bi bi-chevron-right"></i></p>
            </Col>
          </Row>

          <h2 className="mb-2 mt-5">Beneficiary Details</h2>
          <p className="text-muted">Add correct details of your beneficiary</p>
        </div>

        {/* Display error alert if an error occurs */}
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}

        <Form>
          <Form.Group className="mb-4">
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter beneficiary's first name"
              value={firstName}
              onChange={(e) => handleFirstNameChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter beneficiary's last name"
              value={lastName}
              onChange={(e) => handleLastNameChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Enter beneficiary's email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control 
              type="tel" 
              placeholder="Enter beneficiary's phone number"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Relationship</Form.Label>
            <Form.Select
              value={relationship}
              onChange={(e) => setRelationship(e.target.value)}
            >
              <option value="">-- Select Relationship --</option>
              {beneficiaryRelationships.map((relationship) => (
                <option key={relationship.id} value={relationship.title}>
                  {relationship.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

        </Form>
      </Container>
      <div className="text-center">
        <div className="fixed-bottom w-100 py-3 bg-white border-top ">
          <Button
            className={`btn w-100 ${allFieldsComplete ? "custom-primary-btn" : "btn-light"}`}
            onClick={handleContinue}
            disabled={!allFieldsComplete}
            style={{ 
              maxWidth: "800px",
              backgroundColor: allFieldsComplete ? "#EB5017" : "btn-light", 
              color: allFieldsComplete ? "#fff" : "btn-light", 
              borderColor: allFieldsComplete ? "#EB5017" : "btn-light", 
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryDetails;
