import React, { useState } from "react";
import { Button, Row, Col, Container, Card, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PlanDetails = () => {

  const navigate = useNavigate();

  const handleContinue = () => {
    // Add logic to handle continue button click
    console.log("Continue button clicked");
    navigate("/subscription");
  };

  return (
    <div className="auth-page-wrapper">
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="text-center">
          <h2 className="mb-4">Details of your Plan</h2>
        </div>

        <Row className="mb-3">
          <Col></Col>
          <Col
            md={8} // Make the middle column larger
          >
            <Card style={{ borderColor: "#b2b2b2" }}>
              <Card.Body>
              {/* Card 1 */}
              <div>
                <h6>Ella Briggs</h6>

                <hr />

                <div className="d-flex justify-content-between">
                    <div>
                        <div>
                            <span className="text-muted small">Benefit (Over 6 months)</span>
                        </div>
                        <div>
                            <span>₦100,000/Month</span>
                        </div>
                    </div>

                    <div className="text-end">
                        <div>
                            <span className="text-muted small">Claims Eligibility Date</span>
                        </div>
                        <div>
                            <span>Jan 21, 2024</span>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                    <div>
                        <div>
                            <span className="text-muted small">Policy No.</span>
                        </div>
                        <div>
                            <span>IP000246</span>
                        </div>
                    </div>

                    <div className="text-end">
                        <div>
                            <span className="text-muted small">Start Date</span>
                        </div>
                        <div>
                            <span>Feb 12, 2024</span>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                    <div>
                        <div>
                            <span className="text-muted small">Renewal</span>
                        </div>
                        <div>
                            <span>Annual</span>
                        </div>
                    </div>

                    <div className="text-end">
                        <div>
                            <span className="text-muted small">Renewal Date</span>
                        </div>
                        <div>
                            <span>Mar 12, 2024</span>
                        </div>
                    </div>
                </div>
              </div>

              </Card.Body>
            </Card>

            <Card style={{ borderColor: "#b2b2b2" }}>
              <Card.Body>
                <p className="text-primary">Basic</p>
                <h2>₦100,000</h2>
                <p>Annual coverage for your healthcare</p>
                <hr />
                <div className="d-flex justify-content-between">
                    <p>Per month</p>
                    <p className="fw-bold">₦ 1,230</p>
                </div>
                <Accordion defaultActiveKey='0' className="accordion-border-box">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="h2">
                            View coverage benefits
                        </Accordion.Header>
                        <Accordion.Body>
                            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

              </Card.Body>
            </Card>

            <Card style={{ borderColor: "#b2b2b2" }}>
              <Card.Body>
                <h6>Unity Clinics</h6>
                <p>Plot D1, Phase 2, Gado Nasco Way, Kubwa</p>
                <Button
                    style={{ backgroundColor: "#FFECE5", border: "none", color: "#F56630" }}
                    className="btn w-100"
                    onClick={() => console.log("Change Hospital clicked")}
                >
                    Change hospital
                </Button>

              </Card.Body>
            </Card>

          </Col>
          <Col></Col>
        </Row>
      </Container>
      <div className="text-center">
        <div className="fixed-bottom w-100 py-3 bg-white border-top ">
          <Button className="btn custom-primary-btn w-100" onClick={handleContinue} 
            style={{ 
              maxWidth: "500px",
              backgroundColor: "#EB5017",
              color: "#fff",
              borderColor: "#EB5017",
            }}
          >
            Continue with plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
