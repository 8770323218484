import React, { useState, useEffect } from "react";
import { Button, Img, List, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import RecentTransactions from "./RecentTransaction";
import Guides from "./Guides";
import ExplorePlans from "./ExplorePlans";
import { getUserAccountApi } from "../../Common/dataNew/GetUserAccount";

const HomepagePage = () => {

  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    // Fetch user account data from the API and set the state
    const fetchUserAccountData = async () => {
        try {
            const userData = await getUserAccountApi();
            setFirstName(userData.first_name);
        } catch (error) {
            console.error('Error fetching user account data:', error.message);
        }
    };

    fetchUserAccountData();  // Call the fetchUserAccountData function when the component mounts
  }, []);

  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto w-full">
        <Sidebar1 activeItem="Home" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="bg-gray-50 flex flex-1 flex-col font-inter pb-1 md:px-5 relative w-full">
          <div className="bg-deep_orange-500 h-[189px] mx-auto w-full"></div>
          <div className="flex flex-col gap-6 items-center justify-start mt-[-85px] mx-auto w-auto z-[1]">
            <div className="flex md:flex-col flex-row gap-3 items-start justify-start max-w-[1172px] w-full">
              <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-1 flex-col gap-8 items-center justify-start p-6 sm:px-5 rounded w-full">
                <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-start justify-start rounded shadow-bs2 w-full">
                  <div className="bg-white-A700 border-b border-blue_gray-100 border-solid flex flex-col items-center justify-between px-5 py-6 w-full">
                    <div className="flex sm:flex-col flex-row gap-2.5 items-center justify-start w-full">
                      <div className="flex flex-col h-[68px] items-center justify-start w-[68px]">
                        <Img
                          className="h-[68px] md:h-auto rounded-[50%] w-[68px]"
                          src="images/img_image_68x68.png"
                          alt="image"
                        />
                      </div>
                      <div className="flex sm:flex-1 flex-col gap-1 items-start justify-center w-auto sm:w-full">
                        <Text
                          className="sm:text-2xl md:text-[26px] text-[28px] text-gray-900_01 tracking-[-0.56px] w-auto"
                          size="txtInterBold28"
                        >
                          Welcome {firstName},
                        </Text>
                        <Text
                          className="text-base text-blue_gray-500 w-auto"
                          size="txtInterRegular16Bluegray500"
                        >
                          Another day to make insurance easily accessible,
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold min-w-[250px] rounded-[12px] text-center text-xs"
                          color="yellow_800"
                          size="xs"
                          variant="fill"
                        >
                          COMPLETE YOUR KYC TO BE VERIFIED
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white-A700 flex flex-col items-start justify-start w-full">
                    <List
                      className="sm:flex-col flex-row md:gap-5 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
                      orientation="horizontal"
                    >
                      <div className="bg-white-A700 border-blue_gray-100 border-r border-solid flex flex-1 flex-col items-start justify-start sm:ml-[0] px-5 py-6 w-full">
                        <div className="flex flex-col gap-4 items-start justify-start w-auto">
                          <div className="flex flex-col gap-2 items-start justify-start w-auto">
                            <Text
                              className="text-base text-blue_gray-500 w-auto"
                              size="txtInterRegular16Bluegray500"
                            >
                              Total Cash Due
                            </Text>
                            <Text
                              className="text-gray-900_01 text-xl tracking-[-0.40px] w-auto"
                              size="txtInterSemiBold20"
                            >
                              ₦ 0.00
                            </Text>
                          </div>
                          <Button
                            className="cursor-pointer font-semibold min-w-[127px] text-center text-sm"
                            shape="square"
                            color="deep_orange_500"
                            size="md"
                            variant="fill"
                          >
                            Fund Account
                          </Button>
                        </div>
                      </div>
                      <div className="bg-white-A700 border-blue_gray-100 border-r border-solid flex flex-1 flex-col h-full items-start justify-start sm:ml-[0] px-5 py-6 w-full">
                        <div className="flex flex-col gap-4 items-start justify-start w-auto">
                          <div className="flex flex-col gap-2 items-start justify-start w-auto">
                            <Text
                              className="text-base text-blue_gray-500 w-auto"
                              size="txtInterRegular16Bluegray500"
                            >
                              Policies
                            </Text>
                            <Text
                              className="text-gray-900_01 text-xl tracking-[-0.40px] w-auto"
                              size="txtInterSemiBold20"
                            >
                              0
                            </Text>
                          </div>
                          <Button
                            className="cursor-pointer font-semibold min-w-[138px] text-center text-sm"
                            shape="square"
                            color="blue_700"
                            size="md"
                            variant="fill"
                          >
                            Add New Policy
                          </Button>
                        </div>
                      </div>
                    </List>
                  </div>
                </div>
                <ExplorePlans></ExplorePlans>
              </div>
              <Guides></Guides>
            </div>
            <RecentTransactions></RecentTransactions>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomepagePage;
