import React, { useState, ChangeEvent, useRef } from "react";
import { Form, Button, Row, Col, Container} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import health from "../../Common/dataNew/image_health_insurance.svg";

const CategorySelectionPage = () => {
    const [selectedCategory, setSelectedCategory] = useState("");
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(3).fill(null));

    const navigate = useNavigate();

    const handleCategorySelection = (category: string) => {
        setSelectedCategory(category);
    };

    const handleContinue = () => {
        // Add logic to handle continue button click based on the selected category
        console.log("Selected Category:", selectedCategory);
        navigate("/planselection");
    };

    return (
        <div className="auth-page-wrapper text-center">
        <Container className="text-center" style={{ maxWidth: "800px", margin: "0 auto" }}>
            <h2>Your account has been created! Let's get you covered.</h2>
            <p>Select a policy to proceed.</p>

            <Row className="mb-3">
                <Col 
                    style={{
                        backgroundColor: "#B6D8FF",
                        borderRadius: "10px",
                        margin: "10px",
                    }}
                    className="pt-5 border border-primary"
                >
                    <Form.Group>
                        <Form.Check
                            type="radio"
                            id="health"
                            label={
                                <React.Fragment>
                                    <img
                                        src={health}
                                        alt="health"
                                        height={22}
                                        className="rounded-circle img-fluid p-3 mb-3 bg-primary"
                                    />
                                    <h3>Health Insurance</h3>
                                    <p className="text-muted">Affordable health care coverage.</p>
                                </React.Fragment>
                            }
                            name="category"
                            checked={selectedCategory === "health"}
                            onChange={() => handleCategorySelection("health")}
                            ref={(ref: HTMLInputElement | null) => (inputRefs.current[0] = ref)}
                        />
                    </Form.Group>
                </Col>
                <Col
                    //style={{
                    //    backgroundColor: "#91D6A8",
                    //    borderRadius: "10px",
                    //    margin: "10px",
                    //}}
                    //className="pt-5 border border-success"
                >
                    {/* <Form.Group>
                        <Form.Check
                            type="radio"
                            id="life"
                            label={
                                <React.Fragment>
                                    <img
                                        src={health}
                                        alt="health"
                                        height={22}
                                        className="rounded-circle img-fluid p-3 bg-primary"
                                    />
                                    <h3>Life Insurance</h3>
                                    <p className="text-muted">Affordable life insurance coverage.</p>
                                </React.Fragment>
                            }
                            name="category"
                            checked={selectedCategory === "life"}
                            onChange={() => handleCategorySelection("life")}
                            ref={(ref: HTMLInputElement | null) => (inputRefs.current[1] = ref)}
                        />
                    </Form.Group> */}
                </Col>
                <Col
                    //style={{
                    //    backgroundColor: "#F7D394",
                    //    borderRadius: "10px",
                    //    margin: "10px",
                    //}}
                    // className="pt-5 border border-warning"
                > {/*
                    <Form.Group>
                        <Form.Check
                            type="radio"
                            id="retirement"
                            label={
                                <React.Fragment>
                                    <FaMoneyCheckAlt className="icon-orange mb-4" size={40} />
                                    <h3>Retirement Plan</h3>
                                    <p className="text-muted">Affordable retirement plan coverage.</p>
                                </React.Fragment>
                            }
                            name="category"
                            checked={selectedCategory === "retirement"}
                            onChange={() => handleCategorySelection("retirement")}
                            ref={(ref: HTMLInputElement | null) => (inputRefs.current[2] = ref)}
                        />
                    </Form.Group> */}
                </Col> 
            </Row>

            <div className="text-center mt-3">
                <p>Do you have an account? <Link to='/auth-signup-basic' className="fw-semibold custom-text-primary text-decoration-underline"> Sign In</Link> </p>
            </div>

        </Container>
            <div className="fixed-bottom w-100 py-3 bg-white border-top ">
                <Button
                    className={`btn w-100 ${selectedCategory ? "custom-primary-button" : "btn-light"}`}
                    onClick={handleContinue}
                    disabled={!selectedCategory} // Disable the button when no radio button is selected
                    style={{
                        maxWidth: "800px",
                        backgroundColor: selectedCategory ? "#EB5017" : "btn-light", 
                        color: selectedCategory ? "#fff" : "btn-light", 
                        borderColor: selectedCategory ? "#EB5017" : "btn-light", 
                    }}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default CategorySelectionPage;
