import axios from 'axios';
import { baseUrl } from './BaseUrl';

export const signupApi = async (email: string, password: string) => {
  console.log("onSubmit function called");
  try {
    const response = await axios.post(`${baseUrl}/user-and-auth/user-account-register`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
