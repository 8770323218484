import React, { useState, ChangeEvent, useRef } from "react";
import { Form, Container, Button } from "react-bootstrap";

const PhoneVerificationPage = () => {
    const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

    const handleVerificationCodeChange = (index: number, value: string) => {
        // Allow only numbers in the verification code field
        const enteredValue = value.replace(/\D/g, '');

        // Update the verification code array with the new value
        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = enteredValue;
        setVerificationCode(updatedVerificationCode);

        // Move focus to the next input field
        if (index < inputRefs.current.length - 1 && enteredValue !== "") {
            inputRefs.current[index + 1]?.focus();
        }

        // Move focus to the previous input field when deleting
        if (index > 0 && enteredValue === "") {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleContinue = () => {
        // Add logic to handle continue button click based on verification code
        console.log("Verification Code:", verificationCode.join(""));
    };

    return (
        <div className="auth-page-wrapper text-center mt-5">
            <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
                <h2>Enter Pass Code</h2>
                <p>Please enter the 6-digit code sent to your phone number</p>
                <p>+234 1234 567 736.</p>

                <Form>
                    <Form.Group className="mb-3 d-flex justify-content-center">
                        {verificationCode.map((digit, index) => (
                            <Form.Control
                                key={index}
                                type="text"
                                placeholder="-"
                                maxLength={1}
                                value={digit}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleVerificationCodeChange(index, e.target.value)
                                }
                                className="mx-1 text-center verification-input"
                                ref={(ref: HTMLInputElement | null) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </Form.Group>
                </Form>
            </Container>

            <div className="fixed-bottom w-100 py-3 bg-white border-top ">
                <Button variant="light" className="btn btn-light w-100" onClick={handleContinue} style={{ maxWidth: "800px" }}>
                    Continue
                </Button>
            </div>
            
        </div>
    );
};

export default PhoneVerificationPage;
