import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";

const Index = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
            <Routes>
              {authProtectedRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={route.component}
                  key={idx}
                />
              ))}
            </Routes>
        }
      />
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={route.component}
          key={idx}
        />
      ))}
    </Routes>
  );
}

export default Index;