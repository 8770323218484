// ExplorePlansSection.js

import React from "react";
import { Text, Img, Button, List } from "../../components"; // Make sure to import the required components from the correct path

const ExplorePlans = () => {
  return (
    <div className="flex flex-col gap-3 items-start justify-start w-full">
      <div className="flex flex-col h-12 md:h-auto items-center justify-between w-full">
        <Text
          className="text-gray-900_01 text-xl tracking-[-0.40px] w-auto"
          size="txtInterBold20"
        >
          Explore other insurance plans
        </Text>
      </div>
      <List
        className="sm:flex-col flex-row gap-[18px] grid md:grid-cols-1 grid-cols-2 justify-start w-full"
        orientation="horizontal"
      >
        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-1 flex-col gap-3 items-start justify-start p-6 sm:px-5 rounded w-full">
          <div className="flex flex-row gap-2.5 items-center justify-start w-auto">
            <Button
              className="flex h-12 items-center justify-center w-12"
              shape="circle"
              color="blue_600"
              size="lg"
              variant="fill"
            >
              <Img src="images/img_television.svg" alt="television" />
            </Button>
            <div className="flex flex-col gap-1 items-start justify-center w-auto">
              <Text
                className="text-base text-gray-900_01 w-auto"
                size="txtInterSemiBold16Gray90001"
              >
                Health Insurance
              </Text>
              <div className="flex flex-col items-center justify-center rounded-lg w-auto">
                <div className="flex flex-col items-center justify-center rounded-lg w-auto">
                  <Text
                    className="text-center text-green-900 text-xs tracking-[-0.06px] w-auto"
                    size="txtInterMedium12Green900"
                  >
                    Active
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8 items-start justify-start w-full">
            <div className="flex flex-col items-start justify-center w-full">
              <Text
                className="leading-[145.00%] max-w-[326px] md:max-w-full text-base text-gray-900_01"
                size="txtInterRegular16Gray90001"
              >
                Maintain your lifestyle, even if you lose your job.
              </Text>
            </div>
            <Button
              className="cursor-pointer font-semibold min-w-[145px] text-center text-sm"
              shape="square"
              color="deep_orange_50_01"
              size="md"
              variant="fill"
            >
              Download Policy
            </Button>
          </div>
        </div>
        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-1 flex-col gap-3 items-start justify-start p-6 sm:px-5 rounded w-full">
          <div className="flex flex-row gap-2.5 items-center justify-start w-auto">
            <Button
              className="flex h-12 items-center justify-center w-12"
              shape="circle"
              color="green_500"
              size="lg"
              variant="fill"
            >
              <Img src="images/img_home.svg" alt="home" />
            </Button>
            <div className="flex flex-col gap-1 items-start justify-center w-auto">
              <Text
                className="text-base text-gray-900_01 w-auto"
                size="txtInterSemiBold16Gray90001"
              >
                Life Insurance
              </Text>
              <div className="flex flex-col items-center justify-center rounded-lg w-auto">
                <div className="flex flex-col items-center justify-center rounded-lg w-auto">
                  <Text
                    className="text-center text-deep_orange-500 text-xs tracking-[-0.06px] w-auto"
                    size="txtInterMedium12Deeporange500"
                  >
                    Add policy
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8 items-start justify-start w-full">
            <div className="flex flex-col items-start justify-center w-full">
              <Text
                className="leading-[145.00%] max-w-[326px] md:max-w-full text-base text-gray-900_01"
                size="txtInterRegular16Gray90001"
              >
                Maintain your lifestyle, even if you lose your job.
              </Text>
            </div>
            <Button
              className="cursor-pointer font-semibold min-w-[90px] text-center text-sm"
              shape="square"
              color="green_50"
              size="md"
              variant="fill"
            >
              Buy now
            </Button>
          </div>
        </div>
      </List>
    </div>
  );
};

export default ExplorePlans;
