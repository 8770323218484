import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { getUserAccountApi } from "../../Common/dataNew/GetUserAccount";
import { confirmPersonalDetailsApi } from "../../Common/dataNew/ConfirmPersonalDetails";
import { useNavigate } from "react-router-dom";

const PersonalDetailsConfirmation = () => {
    // State variables for form inputs
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [allFieldsComplete, setAllFieldsComplete] = useState<boolean>(false);
    const customPrimaryButtonClass = "custom-primary-button";

    const navigate = useNavigate();

    // Effect to check whether all fields are complete
    useEffect(() => {
        setAllFieldsComplete(
            firstName !== "" && lastName !== "" && email !== "" && phoneNumber !== ""
        );
    }, [firstName, lastName, email, phoneNumber]);

    // Function to handle first name input
    const handleFirstNameChange = (value: string) => {
        setFirstName(value);
    };

    // Function to handle last name input
    const handleLastNameChange = (value: string) => {
        setLastName(value);
    };

    // Function to handle email input
    const handleEmailChange = (value: string) => {
        setEmail(value);
    };

    // Function to handle phone number input
    const handlePhoneNumberChange = (value: string) => {
        setPhoneNumber(value);
    };

    const handleContinue = async () => {
        try {
            // Call confirmPersonalDetailsApi to update the details
            await confirmPersonalDetailsApi(firstName, lastName, phoneNumber);

            // Add logic for further actions if needed

            console.log("Details confirmed successfully!");

            navigate("/kyccompletion");
        } catch (error:any) {
            console.error('Error confirming personal details:', error.message);
        }
    };

    useEffect(() => {
        // Fetch user account data from the API and set the state
        const fetchUserAccountData = async () => {
            try {
                const userData = await getUserAccountApi();
                setFirstName(userData.first_name);
                setLastName(userData.surname);
                setEmail(userData.email);
                setPhoneNumber(userData.mobile_number);
            } catch (error:any) {
                console.error('Error fetching user account data:', error.message);
            }
        };

        fetchUserAccountData();  // Call the fetchUserAccountData function when the component mounts
    }, []);

    return (
        <div className="auth-page-wrapper">
            <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
                <div className="text-center">
                    {/* New Row with 3 Columns */}
                    <Row className="mb-3">
                        <Col className="d-flex align-items-center">
                            {/* Content for the first column */}
                            <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                                <span className="font-weight-bold">1</span>
                            </div>
                            <p className="mb-0">ID <i className="bi bi-chevron-right"></i></p>
                        </Col>

                        <Col className="d-flex align-items-center">
                            {/* Content for the first column */}
                            <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark " style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                                <span className="font-weight-bold">2</span>
                            </div>
                            <p className="mb-0">Verification <i className="bi bi-chevron-right"></i></p>
                        </Col>

                        <Col className="d-flex align-items-center">
                            {/* Content for the first column */}
                            <div className="rounded-circle d-flex align-items-center justify-content-center custom-bg-primary" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                                <span className="font-weight-bold text-white">3</span>
                            </div>
                            <p className="mb-0">Personal Details <i className="bi bi-chevron-right"></i></p>
                        </Col>
                    </Row>

                    <h2 className="mb-2 mt-5">Confirm personal details</h2>
                    <p className="text-muted mb-4">Just making sure your details are correct</p>
                </div>

                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your first name"
                                    value={firstName}
                                    onChange={(e) => handleFirstNameChange(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your last name"
                                    value={lastName}
                                    onChange={(e) => handleLastNameChange(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-4">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            readOnly
                        />
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="Enter your phone number"
                            value={phoneNumber}
                            onChange={(e) => handlePhoneNumberChange(e.target.value)}
                        />
                    </Form.Group>

                </Form>
            </Container>
            <div className="text-center">
                <div className="fixed-bottom w-100 py-3 bg-white border-top ">
                <Button
                    className={`btn w-100 ${allFieldsComplete ? customPrimaryButtonClass : "btn-light"}`}
                    onClick={handleContinue}
                    disabled={!allFieldsComplete}
                    style={{ 
                        maxWidth: "800px",
                        backgroundColor: "#EB5017",
                        color: "#fff",
                        borderColor: "#EB5017",
                    }}
                >
                    Continue
                </Button>
                </div>
            </div>
        </div>
    );
};

export default PersonalDetailsConfirmation;
