import React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Line, SelectBox, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";
import Breadcrumb from "../../components/BreadCrumb";

const inputframeOptionsList = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

const SettingsPage = () => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    { text: "Settings", nextText: "Insurance" },
  ];

  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto pb-[45px] w-full">
        <Sidebar1 activeItem="Settings" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-col font-inter gap-8 items-center justify-center md:px-5 w-full md:w-full">
          <Header pageTitle="Settings" />
          <div className="flex flex-col font-inter gap-10 items-start justify-start mb-[19px] ml-[100px] mt-auto md:px-5 w-full z-[1]">
            <Breadcrumb items={breadcrumbItems} />
            <div className="flex flex-col items-start justify-start w-[70%]">
              <div className="bg-white-A700 flex flex-col gap-6 items-start justify-start px-5 py-6 w-full">
                <div className="border-b-2 border-blue_gray-100 border-solid flex flex-row gap-2.5 items-start justify-start w-full">
                  <div className="flex flex-col items-center justify-center rounded-md w-auto">
                    <div
                      className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                      onClick={() => navigate("/settings")}
                    >
                      <div className="flex flex-col items-center justify-center w-auto">
                        <Text
                          className="text-base text-gray-900_01 w-auto"
                          size="txtInterMedium16"
                        >
                          Profile
                        </Text>
                      </div>
                    </div>
                    <Line className="bg-blue_gray-100 h-0.5 w-full" />
                  </div>
                  <div className="flex flex-col items-center justify-center rounded-md w-auto">
                    <div
                      className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                      onClick={() => navigate("/settingsinsurance")}
                    >
                      <div className="flex flex-col items-center justify-center w-auto">
                        <Text
                          className="text-base text-deep_orange-500 w-auto"
                          size="txtInterMedium16Deeporange500"
                        >
                          Insurance
                        </Text>
                      </div>
                    </div>
                    <Line className="bg-deep_orange-500 h-0.5 w-full" />
                  </div>
                  <div className="flex flex-col items-center justify-center rounded-md w-auto">
                    <div
                      className="common-pointer flex flex-col items-center justify-start px-4 py-3 w-auto"
                      onClick={() => navigate("/settingspayment")}
                    >
                      <div className="flex flex-col items-center justify-center w-auto">
                        <Text
                          className="text-base text-gray-900_01 w-auto"
                          size="txtInterMedium16"
                        >
                          Payment
                        </Text>
                      </div>
                    </div>
                    <Line className="bg-blue_gray-100 h-0.5 w-full" />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full">
                  <div className="flex flex-col gap-6 items-start justify-start w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col items-start justify-start w-full">
                        <Text
                          className="text-base text-blue_gray-500 w-auto"
                          size="txtInterRegular16Bluegray500"
                        >
                          Insurance policy details
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col gap-6 items-start justify-start w-full">
                      <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col gap-4 items-center justify-center sm:px-5 px-6 py-8 rounded-[10px] w-full">
                        <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                          <Text
                            className="text-gray-900_01 text-lg w-full"
                            size="txtInterSemiBold18"
                          >
                            Ella Briggs
                          </Text>
                          <Line className="bg-blue_gray-500 h-px w-full" />
                        </div>
                        <div className="flex md:flex-col flex-row gap-14 items-start justify-start w-full">
                          <div className="flex flex-1 flex-col gap-[5px] items-start justify-start w-full">
                            <Text
                              className="text-blue_gray-500 text-xs w-[135px]"
                              size="txtInterRegular12Bluegray500"
                            >
                              Benefit (Over 6 months)
                            </Text>
                            <Text
                              className="text-base text-gray-900_01 w-auto"
                              size="txtInterRegular16Gray90001"
                            >
                              ₦100,000/Month
                            </Text>
                          </div>
                          <div className="flex flex-1 flex-col gap-[5px] items-end justify-start w-full">
                            <Text
                              className="text-blue_gray-500 text-right text-xs w-[121px]"
                              size="txtInterRegular12Bluegray500"
                            >
                              Claims Eligibility Date
                            </Text>
                            <Text
                              className="text-base text-gray-900_01 w-auto"
                              size="txtInterRegular16Gray90001"
                            >
                              Jan 21, 2024
                            </Text>
                          </div>
                        </div>
                        <Line className="bg-blue_gray-500 h-px w-full" />
                        <div className="flex md:flex-col flex-row md:gap-10 gap-[129px] items-start justify-start w-full">
                          <div className="flex flex-1 flex-col gap-[5px] items-start justify-start w-full">
                            <Text
                              className="text-blue_gray-500 text-xs w-[57px]"
                              size="txtInterRegular12Bluegray500"
                            >
                              Policy No.
                            </Text>
                            <Text
                              className="text-base text-gray-900_01 w-auto"
                              size="txtInterRegular16Gray90001"
                            >
                              IP000246
                            </Text>
                          </div>
                          <div className="flex flex-1 flex-col gap-[5px] items-end justify-start w-full">
                            <Text
                              className="text-blue_gray-500 text-right text-xs w-[58px]"
                              size="txtInterRegular12Bluegray500"
                            >
                              Start Date
                            </Text>
                            <Text
                              className="text-base text-gray-900_01 w-auto"
                              size="txtInterRegular16Gray90001"
                            >
                              Feb 12, 2024
                            </Text>
                          </div>
                        </div>
                        <div className="flex md:flex-col flex-row md:gap-10 gap-36 items-start justify-between w-full">
                          <div className="flex flex-1 flex-col gap-[5px] items-start justify-start w-full">
                            <Text
                              className="text-blue_gray-500 text-xs w-[47px]"
                              size="txtInterRegular12Bluegray500"
                            >
                              Renewal
                            </Text>
                            <Text
                              className="text-base text-gray-900_01 w-auto"
                              size="txtInterRegular16Gray90001"
                            >
                              Annual
                            </Text>
                          </div>
                          <div className="flex flex-1 flex-col gap-[5px] items-end justify-start w-full">
                            <Text
                              className="text-blue_gray-500 text-right text-xs w-[77px]"
                              size="txtInterRegular12Bluegray500"
                            >
                              Renewal Date
                            </Text>
                            <Text
                              className="text-base text-gray-900_01 w-auto"
                              size="txtInterRegular16Gray90001"
                            >
                              Mar 12, 2024
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col gap-4 items-start justify-start px-5 py-8 rounded-[10px] w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <div className="flex flex-col gap-3 items-start justify-start w-full">
                            <Text
                              className="text-blue-800 text-sm w-full"
                              size="txtInterSemiBold14Blue800"
                            >
                              Basic{" "}
                            </Text>
                            <Text
                              className="text-4xl sm:text-[32px] md:text-[34px] text-gray-900_01 tracking-[-1.44px] w-full"
                              size="txtInterBold36"
                            >
                              ₦100,00
                            </Text>
                          </div>
                        </div>
                        <Line className="bg-blue_gray-50 h-px w-full" />
                        <div className="flex flex-row gap-[46px] items-center justify-start w-full">
                          <Text
                            className="flex-1 text-blue_gray-500 text-sm w-auto"
                            size="txtInterRegular14Bluegray500"
                          >
                            Per Month
                          </Text>
                          <Text
                            className="text-base text-black-900 w-auto"
                            size="txtInterSemiBold16Black900"
                          >
                            ₦ 1,230
                          </Text>
                        </div>
                        <SelectBox
                          className="h-9 text-left text-sm w-full"
                          placeholderClassName="text-gray-900_01"
                          indicator={
                            <Img
                              className="h-5 w-5"
                              src="images/img_arrowdown.svg"
                              alt="arrow_down"
                            />
                          }
                          isMulti={false}
                          name="inputframe"
                          options={inputframeOptionsList}
                          isSearchable={false}
                          placeholder="View coverage benefits"
                          shape="round"
                          color="white_A700"
                          size="xs"
                          variant="fill"
                        />
                      </div>
                      <div className="flex flex-col gap-6 items-start justify-start w-full">
                        <Text
                          className="text-base text-blue_gray-500 w-[177px]"
                          size="txtInterRegular16Bluegray500"
                        >
                          Insurance policy details
                        </Text>
                        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col gap-6 items-start justify-start px-5 py-6 rounded shadow-bs1 w-full">
                          <div className="flex flex-col items-center justify-start w-auto">
                            <div className="flex flex-col items-center justify-center w-auto">
                              <Text
                                className="text-gray-900_01 text-lg w-auto"
                                size="txtInterSemiBold18"
                              >
                                Unity Clinics
                              </Text>
                            </div>
                          </div>
                          <Text
                            className="text-blue_gray-500 text-sm w-full"
                            size="txtInterRegular14Bluegray500"
                          >
                            Plot D1, Phase 2, Gado Nasco Way, Kubwa
                          </Text>
                          <Button
                            className="cursor-pointer font-semibold text-center text-sm w-full"
                            shape="square"
                            color="deep_orange_50_01"
                            size="md"
                            variant="fill"
                          >
                            Change Hospital
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
