// Breadcrumb.js

import React from "react";
import { Img, Text } from "../";

const Breadcrumb = ({ items }) => {
  return (
    <div className="flex flex-row gap-4 items-center justify-start w-auto">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center justify-start w-auto">
            <Text
              className="text-base text-blue_gray-500 text-center w-auto"
              size="txtInterRegular16Bluegray500"
            >
              {item.text}
            </Text>
          </div>
          {(
            <>
              <Img
                className="h-3 w-3"
                src="images/img_arrowright_blue_gray_500_12x12.svg"
                alt="arrowright"
              />
              <Text
                className="text-base text-center text-gray-900_01 w-auto"
                size="txtInterSemiBold16Gray90001"
              >
                {item.nextText}
              </Text>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
