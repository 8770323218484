import React from "react";

import { useNavigate } from "react-router-dom";

import { createColumnHelper } from "@tanstack/react-table";

import {
  Button,
  Img,
  Input,
  Line,
  List,
  SelectBox,
  ReactTable,
  Text,
} from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";

import TransactionHistoryColumniconhome from "../../components/TransactionHistoryColumniconhome";
import TransactionHistoryContent from "../../components/TransactionHistoryContent";
import TransactionHistoryContent1 from "../../components/TransactionHistoryContent1";
import TransactionHistoryHeader from "../../components/TransactionHistoryHeader";
import TransactionHistoryLeading from "../../components/TransactionHistoryLeading";

import { CloseSVG } from "../../assets/images";

const defaultbuttonOptionsList = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

const TransactionhistoryPage = () => {
  const navigate = useNavigate();

  const tableData = React.useRef([
    {
      circlesone: "Circle 1",
      membersone: "images/img_creditstounsplashcom_1.png",
      status: "ACTIVE",
      completion: "images/img_group1000000856.svg",
    },
    {
      circlesone: "Circle 1",
      membersone: "images/img_creditstounsplashcom_1.png",
      status: "ACTIVE",
      completion: "images/img_group1000000856.svg",
    },
    {
      circlesone: "Circle 1",
      membersone: "images/img_creditstounsplashcom_1.png",
      status: "ACTIVE",
      completion: "images/img_group1000000856.svg",
    },
    {
      circlesone: "Circle 1",
      membersone: "images/img_creditstounsplashcom_1.png",
      status: "ACTIVE",
      completion: "images/img_group1000000856.svg",
    },
    {
      circlesone: "Circle 1",
      membersone: "images/img_creditstounsplashcom_1.png",
      status: "ACTIVE",
      completion: "images/img_group1000000856.svg",
    },
  ]);

  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("circlesone", {
        cell: (info) => (
          <div className="flex flex-row items-start justify-start gap-2.5 py-[15px] pr-[15px] md:p-[]">
            <div className="mt-1 flex h-3.5 w-3.5 flex-col items-center justify-start rounded-[50%] bg-teal-900 p-[3px] shadow-bs">
              <Img
                className="h-[7px] w-[7px] rounded-[50%]"
                src="images/img_ioniconwwalletdefault.svg"
                alt="ioniconwwalletd"
              />
            </div>
            <Text
              className="mt-[5px] text-[9.56px] text-gray-900_01"
              size="txtInterMedium956"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            className="min-w-[307px] text-xs text-blue_gray-300"
            size="txtInterMedium12"
          >
            CIRCLES
          </Text>
        ),
      }),
      tableColumnHelper.accessor("membersone", {
        cell: (info) => (
          <div className="relative flex py-3.5 pr-3.5">
            <div className="mt-auto flex w-[29%]">
              <div className="my-auto flex w-[79%]">
                <div className="my-auto flex w-[69%]">
                  <Img
                    className="my-auto h-[13px] w-[13px] rounded-md object-cover"
                    src="images/img_creditstounsplashcom.png"
                    alt="creditstounspla"
                  />
                  <div className="z-[1] my-auto ml-[-3.71px] flex h-[13px] w-[13px] flex-col items-center justify-start rounded-md outline outline-[1px] outline-gray-900_03">
                    <Img
                      className="h-[13px] w-[13px] rounded-md object-cover md:h-auto"
                      src="images/img_creditstounsplashcom_13x13.png"
                      alt="creditstounspla_One"
                    />
                  </div>
                </div>
                <div className="z-[1] my-auto ml-[-4px] flex h-[13px] w-[13px] flex-col items-start justify-start rounded-md outline outline-[1px] outline-gray-900_03">
                  <Img
                    className="h-[13px] w-3 rounded-md object-cover md:h-auto sm:w-full"
                    src="images/img_creditstopexelscom.png"
                    alt="creditstopexels"
                  />
                </div>
              </div>
              <div className="z-[1] my-auto ml-[-3.99px] flex h-[13px] w-[13px] flex-col items-center justify-start rounded-md outline outline-[1px] outline-gray-900_03">
                <Img
                  className="h-[13px] w-[13px] rounded-md object-cover md:h-auto"
                  src="images/img_elipse5.png"
                  alt="elipsefive"
                />
              </div>
            </div>
            <div className="z-[1] ml-[-4px] mt-auto flex h-[13px] w-[13px] flex-col items-center justify-start rounded-md outline outline-[1px] outline-gray-900_03">
              <Img
                className="h-[13px] w-[13px] rounded-md object-cover md:h-auto"
                alt="creditstounspla_Two"
                src={info?.getValue()}
              />
            </div>
          </div>
        ),
        header: (info) => (
          <Text
            className="min-w-[143px] text-xs text-blue_gray-300"
            size="txtInterMedium12"
          >
            MEMBERS
          </Text>
        ),
      }),
      tableColumnHelper.accessor("status", {
        cell: (info) => (
          <Text
            className="pb-[15px] pl-0.5 pt-[21px] text-[9.56px] text-gray-900_01"
            size="txtInterBold956"
          >
            {info?.getValue()}
          </Text>
        ),
        header: (info) => (
          <Text
            className="min-w-[124px] text-xs text-blue_gray-300"
            size="txtInterMedium12"
          >
            STATUS
          </Text>
        ),
      }),
      tableColumnHelper.accessor("completion", {
        cell: (info) => (
          <div className="flex flex-col items-start justify-end py-[9px] pr-[9px]">
            <Text
              className="mt-2.5 text-xs text-gray-900_01"
              size="txtInterBold12Gray90001"
            >
              60%
            </Text>
            <Img
              className="mt-1 h-px"
              alt="group1000000856"
              src={info?.getValue()}
            />
          </div>
        ),
        header: (info) => (
          <Text
            className="min-w-[116px] text-right text-xs text-blue_gray-300"
            size="txtInterMedium12"
          >
            COMPLETION
          </Text>
        ),
      }),
    ];
  }, []);

  const [inputframevalue, setInputframevalue] = React.useState("");

  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto pb-[45px] w-full">
        <Sidebar1 activeItem="Transaction History" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-col font-inter gap-8 items-center justify-center md:px-5 w-auto md:w-full">
          <Header pageTitle="Transaction History" />
          <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col gap-6 items-start justify-start max-w-[1172px] px-5 py-6 rounded w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex flex-col items-start justify-center w-full">
                <div className="flex md:flex-col flex-row gap-3 items-center justify-start w-full">
                  <div className="flex flex-1 flex-col items-center justify-between w-full">
                    <div className="flex flex-row gap-3 items-start justify-start w-full">
                      <Button
                        className="cursor-pointer font-semibold min-w-[73px] text-base text-center"
                        shape="square"
                        color="deep_orange_500"
                        size="md"
                        variant="outline"
                      >
                        Claims
                      </Button>
                      <Button
                        className="common-pointer bg-transparent cursor-pointer min-w-[124px] text-base text-blue_gray-500 text-center"
                        onClick={() => navigate("/transactionhistoryone")}
                        size="md"
                      >
                        Subscriptions
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row gap-3 items-start justify-center w-auto">
                    <Button
                      className="cursor-pointer font-semibold min-w-[112px] outline-[2px] text-center text-sm"
                      shape="square"
                      color="deep_orange_500"
                      size="md"
                      variant="outline"
                    >
                      File A Claim
                    </Button>
                    <Button
                      className="cursor-pointer flex items-center justify-center min-w-[97px]"
                      leftIcon={
                        <Img
                          className="h-5 mr-2"
                          src="images/img_buttonicon_white_a700.svg"
                          alt="button-icon"
                        />
                      }
                      shape="square"
                      color="deep_orange_500"
                      size="md"
                      variant="fill"
                    >
                      <div className="font-semibold text-center text-sm">
                        Export
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-col gap-px items-start justify-start rounded-[10px] w-full">
              <div className="flex md:flex-col flex-row gap-3 items-start justify-start p-4 w-full">
                <div className="flex flex-1 md:flex-col flex-row gap-3 h-[39px] md:h-auto items-start justify-start w-full">
                  <div className="flex flex-1 flex-col items-start justify-center w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-col items-start justify-start w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <Input
                            name="inputframe"
                            placeholder="Search here..."
                            value={inputframevalue}
                            onChange={(e) => setInputframevalue(e)}
                            className="p-0 placeholder:text-blue_gray-500 sm:pr-5 text-base text-blue_gray-500 text-left w-full"
                            wrapClassName="border border-blue_gray-100 border-solid flex pl-3 pr-[35px] py-[9px] rounded-md shadow-bs w-full"
                            prefix={
                              <Img
                                className="mt-px mb-auto cursor-pointer h-5 mr-2"
                                src="images/img_search_blue_gray_500.svg"
                                alt="search"
                              />
                            }
                            suffix={
                              <CloseSVG
                                fillColor="#667185"
                                className="cursor-pointer h-5 my-auto"
                                onClick={() => setInputframevalue("")}
                                style={{
                                  visibility:
                                    inputframevalue?.length <= 0
                                      ? "hidden"
                                      : "visible",
                                }}
                                height={20}
                                width={20}
                                viewBox="0 0 20 20"
                              />
                            }
                          ></Input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="border border-blue_gray-100 border-solid cursor-pointer flex items-center justify-center min-w-[90px] rounded-lg shadow-bs4"
                    leftIcon={
                      <Img
                        className="h-5 mt-px mr-2"
                        src="images/img_buttonicon_gray_900_01.svg"
                        alt="button-icon"
                      />
                    }
                    color="white_A700"
                    size="md"
                    variant="fill"
                  >
                    <div className="!text-gray-900_01 text-base text-center">
                      Filter
                    </div>
                  </Button>
                </div>
                <div className="flex sm:flex-1 flex-col items-end justify-center w-auto sm:w-full">
                  <SelectBox
                    className="border border-blue_gray-100 border-solid rounded-md shadow-bs4 text-base text-center w-full"
                    placeholderClassName="text-gray-900_01"
                    indicator={
                      <Img
                        className="h-5 w-5"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isSearchable={false}
                    placeholder="Select dates"
                    getOptionLabel={(e) => (
                      <div className="flex items-center">
                        <Img
                          className="h-5 mr-1 w-5"
                          src="images/img_icon_calendar.svg"
                          alt="icon / calendar"
                        />
                        <span>{e.label}</span>
                      </div>
                    )}
                    name="defaultbutton"
                    isMulti={false}
                    options={defaultbuttonOptionsList}
                    shape="round"
                    color="white_A700"
                    size="xs"
                    variant="fill"
                  />
                </div>
              </div>
              {/* <div className="w-full overflow-auto">
                <ReactTable
                  columns={tableColumns}
                  data={tableData.current}
                  rowClass={"border-b border-blue_gray-600"}
                  headerClass=""
                />
              </div> */}
              <List
                className="sm:flex-col flex-row md:gap-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-start w-full"
                orientation="horizontal"
              >
                <div className="flex flex-1 flex-col items-center justify-start sm:ml-[0] w-full">
                  <TransactionHistoryHeader className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full" />
                  <div className="flex flex-col items-start justify-start w-full">
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col h-[53px] md:h-auto items-start justify-start w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryLeading
                      className="flex flex-col items-start justify-start w-full"
                      claimtext="Accident"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start sm:ml-[0] w-[201px]">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full"
                    descriptiontext="ID"
                  />
                  <div className="flex flex-col items-start justify-start w-full">
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="#28373"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start sm:ml-[0] w-[225px]">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-start justify-start w-full"
                    descriptiontext="Date"
                  />
                  <div className="flex flex-col items-start justify-start w-full">
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                      separatorone="images/img_separator.svg"
                    />
                    <TransactionHistoryContent
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      supportivetext="Jan 25, 2024"
                    />
                  </div>
                </div>
                <div className="flex flex-1 flex-col h-[683px] md:h-auto items-start justify-start sm:ml-[0] w-full">
                  <TransactionHistoryHeader
                    className="flex flex-col h-[45px] md:h-auto items-end justify-start w-full"
                    descriptiontext="Status"
                  />
                  <div className="flex flex-col h-full items-start justify-start w-full">
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1
                      className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full"
                      separatorimage="images/img_separator.svg"
                    />
                    <TransactionHistoryContent1 className="flex flex-col h-[53px] md:h-auto items-start justify-center w-full" />
                  </div>
                </div>
              </List>
              <div className="bg-white-A700 flex flex-col items-center justify-center outline outline-blue_gray-100 px-4 w-full">
                <div className="bg-white-A700 flex flex-col items-center justify-end py-4 w-full">
                  <div className="flex sm:flex-col flex-row gap-6 items-center justify-end w-auto">
                    <Text
                      className="text-base text-center text-gray-900_01 w-auto"
                      size="txtInterMedium16"
                    >
                      Page 1 of 30
                    </Text>
                    <div className="flex flex-row gap-1 items-center justify-center w-auto">
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        1
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        2
                      </Button>
                      <Button className="bg-white-A700 border border-deep_orange-500 border-solid cursor-pointer h-6 py-[3px] rounded-md text-black-900 text-center text-sm w-6">
                        3
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        4
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        5
                      </Button>
                      <Button className="bg-white-A700 cursor-pointer h-6 py-[3px] rounded-md text-blue_gray-300 text-center text-sm w-6">
                        6
                      </Button>
                    </div>
                    <div className="flex flex-row gap-4 items-center justify-center w-auto">
                      <div className="flex flex-col items-start justify-start w-auto">
                        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-center justify-center px-3 py-2 rounded-lg shadow-bs w-auto">
                          <div className="flex flex-col items-center justify-center w-5">
                            <Img
                              className="h-5 w-5"
                              src="images/img_arrowleft.svg"
                              alt="arrowleft"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start w-auto">
                        <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-center justify-center px-3 py-2 rounded-lg shadow-bs w-auto">
                          <div className="flex flex-col items-center justify-center w-5">
                            <Img
                              className="h-5 w-5"
                              src="images/img_arrowleft.svg"
                              alt="arrowright"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionhistoryPage;
