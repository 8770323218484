import { Navigate } from 'react-router-dom';

// Import your page components
import Signin from '../pages/User/SignIn';
import CreateAccount from '../pages/User/CreateAccount';
import PhoneVerification from "../pages/User/PhoneVerification";
import ForgotPasswordEmail from "../pages/User/ForgotPasswordEmail";
import ForgotPasswordPhone from "../pages/User/ForgotPasswordPhone";
import CategorySelection from "../pages/User/CategorySelection";
import PlanSelection from "../pages/User/PlanSelection";
import LocationDetails from "../pages/User/LocationDetails";
import PersonalDetails from "../pages/User/PersonalDetails";
import BeneficiaryDetails from "../pages/User/BeneficiaryDetails";
import PlanDetails from "../pages/User/PlanDetails";
import Subscription from "../pages/User/Subscription";
import DetailsVerification from "../pages/User/DetailsVerification";
import VerifyNin from "../pages/User/VerifyNin";
import PersonalDetailsConfirmation from "../pages/User/PersonalDetailsConfirmation";
import KycCompletion from "../pages/User/KycCompletion";

import Home from "../pages/Homepage/index";
import Plans from "../pages/Plans/index";
import Transactionhistory from "../pages/Transactionhistory/index";
import TransactionhistoryOne from "../pages/TransactionhistoryOne/index";
import Documents from "../pages/Documents/index";
import Notifications from "../pages/Notifications/index";
import Settings from "../pages/Settings/index";
import SettingsInsurance from "../pages/SettingsInsurance/index";
import SettingsPayment from "../pages/SettingsPayment/index";

// Define your routes
const authProtectedRoutes = [

  // Home
  { path: "/dashboard", component: <Home /> },
  { path: "/plans", component: <Plans /> },
  { path: "/transactionhistory", component: <Transactionhistory /> },
  { path: "//transactionhistoryone", component: <TransactionhistoryOne /> },
  { path: "/documents", component: <Documents /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/settings", component: <Settings /> },
  { path: "/settingsinsurance", component: <SettingsInsurance /> },
  { path: "/settingspayment", component: <SettingsPayment /> },

  // Add your protected routes here
  { path: '/dashboard', component: <Navigate to="/dashboard" /> },
  { path: '*', component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // user
  // Signin
  { path: "/", component: <Signin /> },
  // CreateAccount
  { path: "/auth-createaccount", component: <CreateAccount /> },
  // PhoneVerification
  { path: "/auth-phoneverification", component: <PhoneVerification /> },
  // ForgotPasswordEmail
  { path: "/auth-forgotpasswordemail", component: <ForgotPasswordEmail /> },
  // ForgotPasswordPhone
  { path: "/auth-forgotpasswordphone", component: <ForgotPasswordPhone /> },
  // CategorySelection
  { path: "/categoryselection", component: <CategorySelection /> },
  // PlanSelection
  { path: "/planselection", component: <PlanSelection /> },
  // LocationSelection
  { path: "/locationdetails", component: <LocationDetails /> },
  // PersonalDetails
  { path: "/personaldetails", component: <PersonalDetails /> },
  // BeneficiaryDetails
  { path: "/beneficiarydetails", component: <BeneficiaryDetails /> },
  // BeneficiaryDetails
  { path: "/plandetails", component: <PlanDetails /> },
  // Subscription
  { path: "/subscription", component: <Subscription /> },
  // DetailsVerification
  { path: "/detailsverification", component: <DetailsVerification /> },
  // VerifyNin
  { path: "/verifynin", component: <VerifyNin /> },
  // PersonalDetailsConfirmation
  { path: "/personaldetailsconfirmation", component: <PersonalDetailsConfirmation /> },
  // KycCompletion
  { path: "/kyccompletion", component: <KycCompletion /> },
];

export { authProtectedRoutes, publicRoutes };
