import React, { useState, useEffect } from "react";
import { Img, Text } from "../";
import { getUserAccountApi } from "../../Common/dataNew/GetUserAccount";

const Header = ({ pageTitle }) => {

  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    // Fetch user account data from the API and set the state
    const fetchUserAccountData = async () => {
        try {
            const userData = await getUserAccountApi();
            setFirstName(userData.first_name);
        } catch (error) {
            console.error('Error fetching user account data:', error.message);
        }
    };

    fetchUserAccountData();  // Call the fetchUserAccountData function when the component mounts
  }, []);

  return (
    <div className="bg-white-A700 flex flex-row md:gap-10 items-center justify-between max-w-[1237px] sm:px-5 px-8 py-6 w-full">
      <Text
        className="text-2xl md:text-[22px] text-gray-900_01 sm:text-xl tracking-[-0.48px] w-auto"
        size="txtInterBold24"
      >
        {pageTitle}
      </Text>
      <div className="flex flex-row font-assistant gap-2 items-center justify-start w-auto">
        <div className="flex flex-row gap-2 items-center justify-start w-auto">
          <div className="flex flex-col h-9 items-center justify-start w-9">
            <Img
              className="h-9 md:h-auto rounded-[50%] w-9"
              src="images/img_image_36x36.png"
              alt="image"
            />
          </div>
          <Text
            className="text-base text-deep_orange-500 w-auto"
            size="txtAssistantRomanBold16"
          >
            {firstName}
          </Text>
        </div>
        <Img
          className="h-[17px] w-4"
          src="images/img_arrowdown_deep_orange_500.svg"
          alt="arrowdown"
        />
      </div>
    </div>
  );
};

export default Header;
