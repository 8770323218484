import React from "react";

import { Img, Text } from "../";

const TransactionHistoryContent = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="bg-white-A700 flex flex-col h-full items-center justify-start p-4 w-full">
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="flex flex-col items-start justify-center w-full">
                {!!props?.supportivetext ? (
                  <Text
                    className="text-base text-blue_gray-500 w-full"
                    size="txtInterRegular16Bluegray500"
                  >
                    {props?.supportivetext}
                  </Text>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {!!props?.separatorone ? (
          <Img
            className="h-px w-full"
            alt="separator_One"
            src={props?.separatorone}
          />
        ) : null}
      </div>
    </>
  );
};

TransactionHistoryContent.defaultProps = {};

export default TransactionHistoryContent;
