import axios from 'axios';
import { baseUrl } from './BaseUrl';

export const getNigerianLGAsApi = async (stateName: string) => {
    console.log("getNigerianLGAsApi function called");
    try {
        const response = await axios.post(`${baseUrl}/user-and-auth/auth/user-register-nigeria-state-lga`, {
            stateName: stateName,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
