import React from "react";
import PropTypes from "prop-types";

const shapes = {
  circle: "rounded-[50%]",
  round: "rounded",
  square: "rounded-none",
};
const variants = {
  fill: {
    yellow_800: "bg-yellow-800 text-white-A700",
    green_500: "bg-green-500",
    green_50: "bg-green-50 text-green-900_01",
    white_A700: "bg-white-A700",
    blue_700: "bg-blue-700 text-white-A700",
    blue_600: "bg-blue-600",
    deep_orange_50_01: "bg-deep_orange-50_01 text-deep_orange-500",
    deep_orange_500: "bg-deep_orange-500 text-white-A700",
    blue_gray_100: "bg-blue_gray-100 text-white-A700",
    deep_orange_700: "bg-deep_orange-700 text-white-A700",
  },
  outline: {
    deep_orange_500:
      "border-b-2 border-deep_orange-500 border-solid text-deep_orange-500",
  },
};
const sizes = {
  xs: "p-[3px]",
  sm: "p-1.5",
  md: "p-[9px]",
  lg: "p-[13px]",
  xl: "p-[17px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round", "square"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf([
    "yellow_800",
    "green_500",
    "green_50",
    "white_A700",
    "blue_700",
    "blue_600",
    "deep_orange_50_01",
    "deep_orange_500",
    "blue_gray_100",
    "deep_orange_700",
  ]),
};

export { Button };
