import React from "react";

import { Img, Text } from "../";

const TransactionHistoryLeading = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="bg-white-A700 flex flex-col h-full items-center justify-start p-4 w-full">
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-base text-gray-900_01 w-full"
                size="txtInterMedium16"
              >
                {props?.claimtext}
              </Text>
            </div>
          </div>
        </div>
        {!!props?.separatorimage ? (
          <Img
            className="h-px w-full"
            alt="separator_One"
            src={props?.separatorimage}
          />
        ) : null}
      </div>
    </>
  );
};

TransactionHistoryLeading.defaultProps = { claimtext: "Health Claim" };

export default TransactionHistoryLeading;
