import axios from 'axios';
import { baseUrl } from './BaseUrl';

export const getNigerianStatesApi = async () => {
    console.log("getNigerianStatesApi function called");
    try {
        const response = await axios.get(`${baseUrl}/user-and-auth/auth/user-register-nigeria-state-name`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
