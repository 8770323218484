// GuidesPage.js

import React from "react";
import { Text, Img, Button, List } from "../../components"; // Make sure to import the required components from the correct path

const Guides = () => {
  return (
    <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col h-[655px] md:h-auto items-start justify-start p-3 rounded w-[346px] md:w-auto">
      <div className="flex flex-col gap-6 items-start justify-start w-full">
        <div className="flex flex-col items-start justify-start p-2.5 w-[268px]">
          <Text
            className="text-gray-900_01 text-xl tracking-[-0.40px] w-auto"
            size="txtInterBold20"
          >
            Guides
          </Text>
        </div>
        <div className="flex flex-col gap-3 items-start justify-start w-full">
          <div className="bg-white-A700 flex flex-col items-start justify-start outline outline-[0.5px] outline-blue_gray-100 px-5 py-4 rounded w-full">
            <div className="flex flex-col gap-3 items-start justify-start w-full">
              <Text
                className="text-base text-gray-900_01 w-full"
                size="txtInterSemiBold16Gray90001"
              >
                Your Policy
              </Text>
              <Text
                className="leading-[145.00%] max-w-[282px] md:max-w-full text-base text-blue_gray-500"
                size="txtInterRegular16Bluegray500"
              >
                See what is covered and what your policy does not cover. You can
                also request to have a copy and we will send it to your email
              </Text>
              <Button
                className="cursor-pointer font-semibold min-w-[145px] text-center text-sm"
                shape="square"
                color="deep_orange_50_01"
                size="md"
                variant="fill"
              >
                Download Policy
              </Button>
            </div>
          </div>
          <List
            className="flex flex-col gap-3 items-center w-full"
            orientation="vertical"
          >
            <div className="bg-white-A700 flex flex-1 flex-col items-start justify-start outline outline-[0.5px] outline-blue_gray-100 px-5 py-4 rounded w-full">
              <div className="flex flex-col gap-3 items-start justify-start w-full">
                <Text
                  className="text-base text-gray_900_01 w-full"
                  size="txtInterSemiBold16Gray90001"
                >
                  Renewals Due
                </Text>
                <Text
                  className="text-base text-blue_gray-500 w-full"
                  size="txtInterRegular16Bluegray500"
                >
                  No policies due for renewal
                </Text>
              </div>
            </div>
            <div className="bg-green-50 flex flex-1 flex-row gap-2 items-start justify-start outline outline-[0.5px] outline-blue_gray-100 sm:px-5 px-6 py-4 rounded w-full">
              <Img
                className="h-[19px] w-[19px]"
                src="images/img_arrowright.svg"
                alt="arrowright"
              />
              <div className="flex flex-1 flex-col items-start justify-start w-full">
                <Text
                  className="line-through text-base text-blue_gray-500 w-full"
                  size="txtInterSemiBold16Bluegray500"
                >
                  Add a payment method
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-row gap-2 items-start justify-start outline outline-[0.5px] outline-blue_gray-100 sm:px-5 px-6 py-4 rounded w-full">
              <Img
                className="h-[19px] w-[19px]"
                src="images/img_arrowright_gray_900_01.svg"
                alt="arrowright"
              />
              <div className="flex flex-1 flex-col items-start justify-start w-full">
                <Text
                  className="text-base text-gray_900_01 w-full"
                  size="txtInterSemiBold16Gray90001"
                >
                  Complete your KYC
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-row gap-2 items-start justify-start outline outline-[0.5px] outline-blue_gray-100 sm:px-5 px-6 py-4 rounded w-full">
              <Img
                className="h-[19px] w-[19px]"
                src="images/img_arrowright_gray_900_01.svg"
                alt="arrowright"
              />
              <div className="flex flex-1 flex-col items-start justify-start w-full">
                <Text
                  className="text-base text-gray_900_01 w-full"
                  size="txtInterSemiBold16Gray90001"
                >
                  Send us a feedback
                </Text>
              </div>
            </div>
          </List>
        </div>
      </div>
    </div>
  );
};

export default Guides;
