// RecentTransactionsPage.js

import React from "react";
import { Text, Img, Button } from "../../components";
import Help from "../../components/Help"; // Make sure to import the Help component from the correct path

const RecentTransactions = () => {
  return (
    <div className="bg-blue_gray-50 md:h-[163px] h-[237px] p-6 sm:px-5 relative w-[1172px] md:w-full">
      <div className="absolute flex flex-col h-max inset-[0] items-center justify-center m-auto w-[96%] md:w-full">
        <div className="flex flex-col gap-3 items-center justify-start w-full">
          <div className="flex flex-col h-12 md:h-auto items-center justify-between w-full">
            <Text
              className="text-gray-900_01 text-xl tracking-[-0.40px] w-auto"
              size="txtInterBold20"
            >
              Recent transactions
            </Text>
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-center justify-center p-6 sm:px-5 rounded w-full">
              <div className="flex flex-col gap-2.5 items-center justify-center w-auto">
                <Button
                  className="flex h-12 items-center justify-center w-12"
                  shape="circle"
                  color="blue_600"
                  size="lg"
                  variant="fill"
                >
                  <Img
                    className="h-6"
                    src="images/img_boldduotonemessages.svg"
                    alt="boldduotonemess"
                  />
                </Button>
                <Text
                  className="text-base text-blue_gray-500 w-auto"
                  size="txtInterRegular16Bluegray500"
                >
                  No Recent transactions
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Help />
    </div>
  );
};

export default RecentTransactions;
