import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { updatePersonalDetailsApi, getUserEmailFromLocalStorage } from "../../Common/dataNew/UpdateUserAccountPersonalDetails";
import { getNetworkProvidersApi } from "../../Common/dataNew/NetworkProviders";
import { useNavigate } from "react-router-dom";

const PersonalDetails = () => {
  // State variables for form inputs
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedNetworkProvider, setSelectedNetworkProvider] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [networkProviders, setNetworkProviders] = useState<string[]>([]);
  const [allFieldsComplete, setAllFieldsComplete] = useState<boolean>(false);
  const customPrimaryButtonClass = "custom-primary-button";

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch network providers when the component mounts
    const fetchNetworkProviders = async () => {
      try {
        const providers = await getNetworkProvidersApi();
        // Explicitly type the provider parameter
        const providerTitles = providers.map((provider: { title: string }) => provider.title);
        setNetworkProviders(providerTitles);
      } catch (error:any) {
        console.error('Error fetching network providers:', error.message);
      }
    };    
  
    fetchNetworkProviders();

    // Extract email from local storage
    const userInfo = getUserEmailFromLocalStorage();
    if (userInfo) {
      setEmail(userInfo);
    }
  }, []);

  // Effect to check whether all fields are complete
  useEffect(() => {
    setAllFieldsComplete(
      firstName !== "" && lastName !== "" && email !== "" && selectedNetworkProvider !== "" && phoneNumber !== ""
    );
  }, [firstName, lastName, email, selectedNetworkProvider, phoneNumber]);

  const handleContinue = async () => {
    try {
      // Call the API function to update personal details
      await updatePersonalDetailsApi(lastName, firstName, selectedNetworkProvider, phoneNumber);
      console.log('Personal details updated successfully!');

      navigate("/beneficiarydetails");
    } catch (error:any) {
      console.error('Error updating personal details:', error.message);
      // Handle error or display an error message to the user
    }
  };

  // Function to handle first name input
  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };

  // Function to handle last name input
  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  // Function to handle email input
  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  // Function to handle network provider selection
  const handleNetworkProviderChange = (value: string) => {
    setSelectedNetworkProvider(value);
  };

  // Function to handle phone number input
  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  return (
    <div className="auth-page-wrapper">
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="text-center">
          {/* New Row with 3 Columns */}
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">1</span>
              </div>
              <p className="mb-0">Plan Details <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark " style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">2</span>
              </div>
              <p className="mb-0">Location Details <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center custom-bg-primary" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold text-white">3</span>
              </div>
              <p className="mb-0">Personal Details <i className="bi bi-chevron-right"></i></p>
            </Col>
          </Row>

          <h2 className="mb-2 mt-5">Personal Details</h2>
          <p className="text-muted">Please ensure that all details provided are similar to details in a recognized Identity Card</p>
        </div>

        <Form>
          <Form.Group className="mb-4">
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e) => handleFirstNameChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter your last name"
              value={lastName}
              onChange={(e) => handleLastNameChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              readOnly // Make the email input non-editable
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Select Network Provider</Form.Label>
            <Form.Select
              value={selectedNetworkProvider}
              onChange={(e) => handleNetworkProviderChange(e.target.value)}
            >
              <option value="">-- Select Network Provider --</option>
              {networkProviders.map((provider) => (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control 
              type="tel" 
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
            />
          </Form.Group>

        </Form>
      </Container>
      <div className="text-center">
        <div className="fixed-bottom w-100 py-3 bg-white border-top ">
          <Button
            className={`btn w-100 ${allFieldsComplete ? customPrimaryButtonClass : "btn-light"}`}
            onClick={handleContinue}
            disabled={!allFieldsComplete}
            style={{ 
              maxWidth: "800px",
              backgroundColor: allFieldsComplete ? "#EB5017" : "btn-light", 
              color: allFieldsComplete ? "#fff" : "btn-light", 
              borderColor: allFieldsComplete ? "#EB5017" : "btn-light", 
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
