import axios from 'axios';
import { baseUrl } from './BaseUrl';

export const forgotPasswordApi = async (email: string) => {
    console.log("forgotPasswordApi function called");
    try {
      const response = await axios.post(`${baseUrl}/user-and-auth/auth/login-forgot-password`, {
        email,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
};
  