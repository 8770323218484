import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { getIdentityTypesApi, IdentityType } from "../../Common/dataNew/GetIndentityTypes";
import { useNavigate } from "react-router-dom";

const DetailsVerification = () => {
  // State variables for form inputs
  const [IDType, setIDType] = useState("");
  const [identityTypes, setIdentityTypes] = useState<IdentityType[]>([]);

  const navigate = useNavigate();

  const fetchIdentityTypes = async () => {
    try {
      const types = await getIdentityTypesApi();
      setIdentityTypes(types);
    } catch (error:any) {
      console.error('Error fetching identity types:', error.message);
      // Handle error as needed
    }
  };

  useEffect(() => {
    // Fetch identity types when the component mounts
    fetchIdentityTypes();
  }, []);

  // New function to handle IDType change
  const handleIDTypeChange = (value: string) => {
    setIDType(value);
  };

  const handleContinue = () => {
    // Add logic to handle continue button click based on form inputs
    console.log("Beneficiary Details:");
    console.log("ID Type:", IDType);

    navigate("/verifynin");
  };

  return (
    <div className="auth-page-wrapper" style={{ minHeight: "100vh" }}>
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="text-center">
          {/* New Row with 3 Columns */}
          <Row className="mb-3">
            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">1</span>
              </div>
              <p className="mb-0">ID <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center custom-bg-primary" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold text-white">2</span>
              </div>
              <p className="mb-0">Verification <i className="bi bi-chevron-right"></i></p>
            </Col>

            <Col className="d-flex align-items-center">
              {/* Content for the first column */}
              <div className="rounded-circle d-flex align-items-center justify-content-center bg-white border border-dark" style={{ width: '30px', height: '30px', marginRight: '10px' }}>
                <span className="font-weight-bold">3</span>
              </div>
              <p className="mb-0">Personal Details <i className="bi bi-chevron-right"></i></p>
            </Col>
          </Row>

          <h2 className="mb-2 mt-5">Let’s verify your details</h2>
          <p className="text-muted">APlease provide us with your identification. This information will be kept private</p>
        </div>

        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Select ID type</Form.Label>
            <Form.Select
              value={IDType}
              onChange={(e) => handleIDTypeChange(e.target.value)}
            >
              <option value="">-- Select ID type --</option>
              {identityTypes.map((type) => (
                <option key={type.id} value={type.title}>
                  {type.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>

        <div className="text-center">
            {/* New <a> tag */}
            <a href="#" className="custom-text-primary">
                or Upload A Government Issued ID
            </a>
        </div>
      </Container>
      <div className="text-center">
        <div className="fixed-bottom w-100 py-3 bg-white border-top ">
          <Button variant="light" className="btn btn-light w-100" onClick={handleContinue} 
            style={{ 
              maxWidth: "800px",
              backgroundColor: "#EB5017",
              color: "#fff",
              borderColor: "#EB5017",
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailsVerification;
