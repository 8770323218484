import React, { useState, useRef } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import star from "../../assets/images/stars.png";

const PlanSelectionPage = () => {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedTerm, setSelectedTerm] = useState("monthly"); // Default to monthly
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(3).fill(null));

    const navigate = useNavigate();

    const handleCategorySelection = (category: string) => {
        setSelectedCategory(category);
    };

    const handleTermChange = (term: string) => {
        setSelectedTerm(term);
    };

    const handleContinue = () => {
        // Add logic to handle continue button click based on the selected category and term
        console.log("Selected Category:", selectedCategory);
        console.log("Selected Term:", selectedTerm);
        navigate("/locationdetails");
    };

    return (
        <div className="auth-page-wrapper">
            <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
                <div className="text-center">
                    <h2 className="mb-3">Compare our plan and select the plan that works for you</h2>
                    <div className="mb-3">
                        <Button
                            variant={selectedTerm === "monthly" ? "warning" : "light"}
                            onClick={() => handleTermChange("monthly")}
                        >
                            Monthly
                        </Button>{" "}
                        <Button
                            variant={selectedTerm === "yearly" ? "warning" : "light"}
                            onClick={() => handleTermChange("yearly")}
                        >
                            Yearly
                        </Button>
                    </div>
                </div>

                <Row className="mb-3">
                    <Col
                        style={{
                            borderRadius: "10px",
                            margin: "10px",
                            padding: "20px",
                            border: "1px solid #ddd",
                        }}
                    >
                        <Form.Group>
                            <div
                                className={`policy-item${selectedCategory === "health" ? " selected" : ""}`}
                                onClick={() => handleCategorySelection("health")}
                            >
                                <p className="text-primary">Basic</p>
                                <h1>₦100,000</h1>
                                <p>Annual coverage for your healthcare</p>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <p>Per month</p>
                                    <p className="fw-bold">₦ 1,230</p>
                                </div>
                                <div>
                                    <p className="fw-bold">Benefits include</p>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col
                        style={{
                            borderRadius: "10px",
                            margin: "10px",
                            padding: "20px",
                            border: "1px solid #ddd",
                        }}
                    >
                        <Form.Group>
                            <div
                                className={`policy-item${selectedCategory === "health" ? " selected" : ""}`}
                                onClick={() => handleCategorySelection("health")}
                            >
                                <p  style={{ color: "#0F973D" }}>Standard</p>
                                <h1>₦100,000</h1>
                                <p>Annual coverage for your healthcare</p>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <p>Per month</p>
                                    <p className="fw-bold">₦ 1,230</p>
                                </div>
                                <div>
                                    <p className="fw-bold">Benefits include</p>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col
                        style={{
                            backgroundColor: "#FFECE5",
                            borderRadius: "10px",
                            margin: "10px",
                            padding: "20px",
                            border: "1px solid #ddd",
                        }}
                    >
                        <Form.Group>
                            <div
                                className={`policy-item${selectedCategory === "health" ? " selected" : ""}`}
                                onClick={() => handleCategorySelection("health")}
                            >
                                <p className="custom-text-primary d-flex">
                                    Premium
                                    <span className="badge custom-primary-btn ml-2 d-flex"><img src={star} alt="star" className="ml-2" /> BEST FOR SOFT LIFE</span>
                                </p>
                                <h1>₦50,000</h1>

                                <p>Use airtime for annual coverage for your healthcare</p>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <p>Per month</p>
                                    <p className="fw-bold">₦ 1,230</p>
                                </div>
                                <div>
                                    <p className="fw-bold">Benefits include</p>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                    <Row className="benefit-row mb-2">
                                        <Col xs="auto" style={{ color: "#0F973D" }}>&#10003;</Col>
                                        <Col>Annual coverage for your healthcare</Col>
                                    </Row>
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <div className="text-center">
                <div className="fixed-bottom w-100 py-3 bg-white border-top ">
                    <Button variant="light" className="btn btn-light w-100" onClick={handleContinue}    style={{ 
                        maxWidth: "800px", 
                        backgroundColor: "#EB5017",
                        color: "#fff",
                        borderColor: "#EB5017", 
                    }}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PlanSelectionPage;
