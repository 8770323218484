import axios from 'axios';
import { baseUrl } from './BaseUrl';

export const getNetworkProvidersApi = async () => {
    console.log("getNetworkProvidersApi function called");
    try {
        const response = await axios.get(`${baseUrl}/user-and-auth/user-account-network-provider`);
        return response.data.result;
    } catch (error) {
        throw error;
    }
};
