import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row, Image, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dummyUser from "../../assets/images/users/img_image.png";

import logolight from "../../assets/images/logo-light.png";
import authbackground from "../../assets/images/auth-bg.jpg";

const ForgotPasswordPhone = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            phone: "",
        },
        onSubmit: async (values) => {
            try {
                // Add logic here to handle forgot password email submission
                console.log('Phone submitted:', values.phone);
                // Redirect or show success message as needed
            } catch (error:any) {
                console.error('Error submitting phone:', error.message);
            }
        },
    });

    const handleTogglePassword = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    return (
        <React.Fragment>
            <section className="position-relative min-vh-100 d-flex align-items-center justify-content-between">
                <Container fluid>
                    <div className="h-100 d-flex">
                        <div
                            className="text-white p-md-5 d-flex flex-column justify-content-between m-3"
                            style={{ 
                                width: '50%', 
                                borderRadius: "2em",
                                backgroundImage: `url(${authbackground})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div>
                                <Image src={logolight} className="card-logo card-logo-dark" alt="logo dark" style={{ height: '16px' }} />
                                <h3 className="text-white mt-4 text-wrap" style={{ fontSize: "3rem"}}>
                                    Your gateway to hassle-free insurance
                                </h3>

                                <p className="text-muted fs-14 mt-3 lh-base" style={{ color: "#E4DBDB" }}>
                                    Say goodbye to complex forms and hello to simplicity. We've made it easy—just use your airtime to get started. Protect what matters most without the fuss.
                                </p>
                            </div>
                            <div
                                className="p-4"
                                style={{ backgroundColor: "#3E3838", borderRadius: "2em" }}
                            >
                                <p className=" fs-13 lh-base" style={{ color: "#F0E6E6" }}>
                                    EVAS has transformed the way I view insurance. The sheer range of options and the easy-to-understand steps have been game-changers. It's like having insurance at the palm of your hands without complicated processes or involving third parties.
                                </p>

                                <div className="d-flex align-items-center mt-3">
                                    <Image className="rounded-circle header-profile-user me-3" src={dummyUser} alt="Header Avatar" />
                                    <div>
                                        <p className="mb-0 fw-bold fs-13 mb-1">Ella Briggs</p>
                                        <p className="text-muted mb-0 fs-11">Fashion Designer</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="auth-page-wrapper align-items-center justify-content-center px-md-5"
                            style={{ overflowY: 'auto', height: '100vh', width: '50%' }}
                        >
                            <div
                                className="auth-card mx-lg-3 "
                                style={{ minWidth: "500px" }}
                            >
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <h2 className="d mb-1">Forgot Password</h2>
                                        <p className="text-muted">
                                            Enter your email address, and instructions will be sent on how to reset your password.
                                        </p>

                                        <Form action="#" onSubmit={formik.handleSubmit}>
                                            <div className="mb-3 mt-3">
                                                <Form.Label htmlFor="email">Phone</Form.Label>
                                                <Form.Control
                                                    type="phone"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="Enter phone number"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.phone && formik.touched.phone ? (
                                                    <span className="text-danger">{formik.errors.phone}</span>
                                                ) : null}
                                            </div>

                                            <div className="mt-4">
                                                <Button
                                                    variant="primary"
                                                    className="w-100 custom-primary-btn"
                                                    type="submit"
                                                    style={{
                                                        backgroundColor: "#EB5017",
                                                        color: "#fff",
                                                        borderColor: "#EB5017",
                                                    }}
                                                >
                                                    Continue
                                                </Button>
                                            </div>
                                        </Form>

                                        <div className="text-center mt-3">
                                            <p>
                                                <Link to='/auth-signup-basic' className="fw-semibold custom-text-primary text-decoration-underline"> Resend Link</Link>
                                            </p>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default ForgotPasswordPhone;
