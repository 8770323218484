// HelpSection.js

import React from "react";
import { Button, Img, Text } from "../../components";

const HelpSection = () => {
  return (
    <div className="fixed flex flex-row items-center justify-end bottom-4 right-4 md:right-[10%] w-[11%] md:w-full">
      <div
        className="bg-cover bg-no-repeat flex flex-col h-10 items-start justify-end p-[9px] shadow-bs3"
        style={{ backgroundImage: "url('images/img_info.svg')" }}
      >
        <Text
          className="md:ml-[0] ml-[3px] mt-1 text-blue-700 text-center text-xs tracking-[-0.60px]"
          size="txtManropeSemiBold12"
        >
          Help?
        </Text>
      </div>
      <Button className="bg-deep_orange-500 flex h-[50px] items-center justify-center p-[13px] rounded-[50%] w-[50px]">
        <Img src="images/img_settings_white_a700.svg" alt="settings_One" />
      </Button>
    </div>
  );
};

export default HelpSection;
