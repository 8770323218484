import React from "react";

import { Img, Input, Line, List, Text } from "../../components";
import Sidebar1 from "../../components/Sidebar1";
import Header from "../../components/Header";

const NotificationsPage = () => {
  return (
    <>
      <div className="bg-gray-50 flex sm:flex-col md:flex-col flex-row font-holtwoodonesc sm:gap-5 md:gap-5 items-start mx-auto w-full">
        <Sidebar1 activeItem="Notifications" className="!sticky !w-[204px] bg-white-A700 border-indigo-50 border-r border-solid flex h-screen md:hidden justify-start overflow-auto md:px-5 rounded-bl-[12px] rounded-tl-[12px] top-[0]" />
        <div className="flex flex-1 flex-col font-inter md:gap-10 gap-20 h-[1024px] md:h-auto items-center justify-start md:px-5 w-full">
          <Header pageTitle="Notifications" />
          <div className="bg-white-A700 flex flex-col h-[712px] md:h-auto items-start justify-start max-w-[685px] p-2.5 rounded-[20px] w-full">
            <div className="bg-white-A700 flex flex-col gap-[30px] items-start justify-start sm:px-5 px-[30px] py-5 rounded-[20px] w-full">
              <div className="flex flex-row gap-2.5 items-center justify-between px-2.5 py-[7.87px] w-full">
                <div className="flex flex-col items-center justify-start w-auto">
                  <Text
                    className="text-base text-gray-900_01 w-auto"
                    size="txtInterSemiBold16Gray90001"
                  >
                    Latest Notifications
                  </Text>
                </div>
                <Text
                  className="text-base text-deep_orange-500 w-auto"
                  size="txtInterRegular16Deeporange500"
                >
                  Clear
                </Text>
              </div>
              <div className="flex flex-col gap-[19px] items-start justify-start w-full">
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-xs w-auto"
                    size="txtInterSemiBold12"
                  >
                    Today
                  </Text>
                  <List
                    className="flex flex-col gap-2.5 items-start w-full"
                    orientation="vertical"
                  >
                    <div className="bg-white-A700 border-b border-blue_gray-100 border-solid flex flex-1 flex-col gap-2 items-start justify-start my-0 py-3 w-full">
                      <div className="flex md:flex-col flex-row gap-1.5 items-center justify-start w-full">
                        <div className="bg-deep_orange-500 h-1.5 rounded-[50%] w-1.5"></div>
                        <div className="flex flex-1 flex-row gap-[6.91px] items-end justify-start w-full">
                          <Text
                            className="flex-1 text-base text-gray-900_01 w-auto"
                            size="txtInterMedium16"
                          >
                            Your import was successful
                          </Text>
                          <Text
                            className="text-blue_gray-500 text-xs w-auto"
                            size="txtInterRegular12Bluegray500"
                          >
                            1min ago
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-row gap-[6.91px] items-end justify-start w-full">
                          <Text
                            className="flex-1 text-blue_gray-500 text-xs w-auto"
                            size="txtInterRegular12Bluegray500"
                          >
                            Admin
                          </Text>
                          <Text
                            className="text-deep_orange-500 text-xs w-auto"
                            size="txtInterRegular12Deeporange500"
                          >
                            Mark as read
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border-b border-blue_gray-100 border-solid flex flex-1 flex-col gap-2 items-start justify-start my-0 py-3 w-full">
                      <div className="flex md:flex-col flex-row gap-1.5 items-center justify-start w-full">
                        <div className="bg-deep_orange-500 h-1.5 rounded-[50%] w-1.5"></div>
                        <div className="flex flex-1 flex-row gap-[6.91px] items-end justify-start w-full">
                          <Text
                            className="flex-1 text-base text-gray-900_01 w-auto"
                            size="txtInterMedium16"
                          >
                            Your import was successful
                          </Text>
                          <Text
                            className="text-blue_gray-500 text-xs w-auto"
                            size="txtInterRegular12Bluegray500"
                          >
                            2min ago
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-row gap-[6.91px] items-end justify-start w-full">
                          <Text
                            className="flex-1 text-blue_gray-500 text-xs w-auto"
                            size="txtInterRegular12Bluegray500"
                          >
                            Admin
                          </Text>
                          <Text
                            className="text-deep_orange-500 text-xs w-auto"
                            size="txtInterRegular12Deeporange500"
                          >
                            Mark as read
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border-b border-blue_gray-100 border-solid flex flex-1 flex-col gap-2 items-start justify-start my-0 py-3 w-full">
                      <div className="flex md:flex-col flex-row gap-1.5 items-center justify-start w-full">
                        <div className="bg-deep_orange-500 h-1.5 rounded-[50%] w-1.5"></div>
                        <div className="flex flex-1 sm:flex-col flex-row gap-[6.91px] items-end justify-start w-full">
                          <Text
                            className="flex-1 text-base text-gray-900_01 w-auto"
                            size="txtInterMedium16"
                          >
                            Your password has been changed successfully
                          </Text>
                          <Text
                            className="text-blue_gray-500 text-xs w-auto"
                            size="txtInterRegular12Bluegray500"
                          >
                            26 Jan, 12:30 PM
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-row gap-[6.91px] items-end justify-start w-full">
                          <Text
                            className="flex-1 text-blue_gray-500 text-xs w-auto"
                            size="txtInterRegular12Bluegray500"
                          >
                            Settings
                          </Text>
                          <Text
                            className="text-deep_orange-500 text-xs w-auto"
                            size="txtInterRegular12Deeporange500"
                          >
                            Mark as read
                          </Text>
                        </div>
                      </div>
                    </div>
                  </List>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="text-blue_gray-500 text-xs w-auto"
                    size="txtInterSemiBold12"
                  >
                    26 Jan
                  </Text>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div className="bg-white-A700 border-b border-gray-100 border-solid flex flex-col items-start justify-start py-3 w-full">
                      <div className="flex flex-col items-start justify-start w-full">
                        <div className="flex flex-col items-end justify-start w-full">
                          <Text
                            className="text-base text-blue_gray-500 w-auto"
                            size="txtInterRegular16Bluegray500"
                          >
                            David Briggs joined via invite link
                          </Text>
                        </div>
                      </div>
                    </div>
                    <Input
                      name="frame237541"
                      placeholder="Your password has been changed successfully"
                      className="p-0 placeholder:text-blue_gray-500 text-base text-left w-full"
                      wrapClassName="flex w-full"
                      type="password"
                      prefix={
                        <div className="h-6 mr-2.5 w-6 bg-deep_orange-50_01 p-1.5 rounded-[50%] flex justify-center items-center">
                          <Img
                            className="h-3 my-auto"
                            src="images/img_search.svg"
                            alt="search"
                          />
                        </div>
                      }
                      shape="round"
                      color="white_A700"
                      size="xs"
                      variant="fill"
                    ></Input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsPage;
