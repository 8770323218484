import React from "react";

const sizeClasses = {
  txtInterRegular16Gray700: "font-inter font-normal",
  txtInterRegular14Gray90001: "font-inter font-normal",
  txtInterRegular14Bluegray500: "font-inter font-normal",
  txtInterSemiBold16Blue800: "font-inter font-semibold",
  txtInterSemiBold16Bluegray500: "font-inter font-semibold",
  txtInterRegular12WhiteA700: "font-inter font-normal",
  txtInterBold28: "font-bold font-inter",
  txtInterSemiBold14Blue800: "font-inter font-semibold",
  txtInterRegular16Deeporange500: "font-inter font-normal",
  txtInterRegular12Black900: "font-inter font-normal",
  txtInterBold24: "font-bold font-inter",
  txtHoltwoodOneSC28Black900: "font-holtwoodonesc font-normal",
  txtInterMedium16Deeporange500: "font-inter font-medium",
  txtInterRegular14Red800: "font-inter font-normal",
  txtInterBold20: "font-bold font-inter",
  txtManropeSemiBold12: "font-manrope font-semibold",
  txtInterRegular16Gray90001: "font-inter font-normal",
  txtInterRegular16Black900: "font-inter font-normal",
  txtInterRegular12Bluegray500: "font-inter font-normal",
  txtAssistantRomanBold16: "font-assistant font-bold",
  txtInterRegular12Deeporange500: "font-inter font-normal",
  txtInterSemiBold16Green800: "font-inter font-semibold",
  txtInterSemiBold16Deeporange700: "font-inter font-semibold",
  txtInterSemiBold16Gray90001: "font-inter font-semibold",
  txtInterSemiBold16Black900: "font-inter font-semibold",
  txtInterSemiBold14Deeporange700: "font-inter font-semibold",
  txtInterRegular16Bluegray500: "font-inter font-normal",
  txtInterSemiBold18Black900: "font-inter font-semibold",
  txtInterSemiBold14: "font-inter font-semibold",
  txtInterSemiBold36: "font-inter font-semibold",
  txtInterBold36: "font-bold font-inter",
  txtInterMedium12: "font-inter font-medium",
  txtHoltwoodOneSC28: "font-holtwoodonesc font-normal",
  txtInterSemiBold12: "font-inter font-semibold",
  txtInterSemiBold18: "font-inter font-semibold",
  txtInterMedium16: "font-inter font-medium",
  txtInterMedium14: "font-inter font-medium",
  txtInterSemiBold16: "font-inter font-semibold",
  txtInterMedium14Green800: "font-inter font-medium",
  txtInterSemiBold60: "font-inter font-semibold",
  txtInterSemiBold20: "font-inter font-semibold",
  txtInterMedium12Green900: "font-inter font-medium",
  txtInterMedium16Bluegray500: "font-inter font-medium",
  txtInterRegular12: "font-inter font-normal",
  txtInterMedium12Deeporange500: "font-inter font-medium",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
  txtInterRegular18: "font-inter font-normal",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
