import axios from 'axios';
import { baseUrl } from './BaseUrl';

// Define the type for beneficiary relationship
export interface BeneficiaryRelationship {
  id: number;
  title: string;
  created_date: string;
}

export const getBeneficiaryRelationshipsApi = async (): Promise<BeneficiaryRelationship[]> => {
  console.log("getBeneficiaryRelationshipsApi function called");
  try {
    const response = await axios.get(`${baseUrl}/user-and-auth/user-account-beneficiary-relationship`);
    return response.data.result as BeneficiaryRelationship[];
  } catch (error) {
    throw error;
  }
};
