import React, { useState, useEffect } from "react";
import { Form, Container } from "react-bootstrap";
import { getNetworkProvidersApi } from "../../Common/dataNew/NetworkProviders";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  // State variables for form inputs
  const [subscriptionBundle, setSubscriptionBundle] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [networkProviders, setNetworkProviders] = useState<string[]>([]);

  const navigate = useNavigate();

  // Function to handle first name input
  useEffect(() => {
    // Fetch network providers when the component mounts
    const fetchNetworkProviders = async () => {
      try {
        const providers = await getNetworkProvidersApi();
        // Explicitly type the provider parameter
        const providerTitles = providers.map((provider: { title: string }) => provider.title);
        setNetworkProviders(providerTitles);
      } catch (error: any) {
        console.error('Error fetching network providers:', error.message);
      }
    };

    fetchNetworkProviders();
  }, []);

  // Function to handle network provider selection
  const handleNetworkProvidersChange = (value: string) => {
    setNetworkProviders([value]); // Wrap the value in an array
  };

  // Function to handle phone number input
  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  // Function to handle phone number input
  const handleSubscriptionBundleChange = (value: string) => {
    setSubscriptionBundle(value);
  };

  const handleContinue = () => {
    // Add logic to handle continue button click based on form inputs
    console.log("Subscription Bundle:", subscriptionBundle);
    console.log("Selected Network Provider:", networkProviders);
    console.log("Phone Number:", phoneNumber);

    navigate("/detailsverification");
  };

  return (
    <div className="auth-page-wrapper">
      <Container style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="text-center">
          <h2 className="mb-2 mt-5">Use your Airtime to complete your subscription</h2>
          <p className="text-muted">Use your Airtime to complete your subscription.</p>
        </div>

        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Select Network Provider</Form.Label>
            <Form.Select
              value={networkProviders[0]} // Access the first element of the array
              onChange={(e) => handleNetworkProvidersChange(e.target.value)}
            >
              <option value="">-- Select Network Provider --</option>
              {networkProviders.map((provider) => (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Select bundle for subscription</Form.Label>
            <Form.Select
              value={subscriptionBundle}
              onChange={(e) => handleSubscriptionBundleChange(e.target.value)}
            >
              <option value="">-- Select Subscription Bundle --</option>
              <option value="500">#500</option>
              <option value="1,000">1,000</option>
            </Form.Select>
          </Form.Group>

          <button className="btn custom-primary-btn w-100" onClick={handleContinue}
            style={{ 
              backgroundColor: "#EB5017",
              color: "#fff",
              borderColor: "#EB5017",
            }}
          >
            Authorize Payment
          </button>

        </Form>
      </Container>
    </div>
  );
};

export default Subscription;
