import React, { useState, useRef } from "react";
import { Button, Container, Image } from "react-bootstrap";
//import { IoIosCheckmarkCircle } from "react-icons/io";

import stars from "../../assets/images/two-stars.png";
import { useNavigate } from "react-router-dom";

const KycCompletion = () => {
    const [selectedCategory, setSelectedCategory] = useState("");
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(3).fill(null));

    const navigate = useNavigate();

    const handleCategorySelection = (category: string) => {
        setSelectedCategory(category);
    };

    const handleContinue = () => {
        // Add logic to handle continue button click based on the selected category
        console.log("Selected Category:", selectedCategory);
        navigate("/");
    };

    return (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            <Container className="text-center" style={{ maxWidth: "800px", margin: "0 auto" }}>
                <h2>You have successfully completed your KYC.</h2>
                <Image
                    className="mt-4 mb-4" // Add margin for spacing
                    src={stars}
                    alt="stars"
                    style={{
                        maxWidth: "100px", // Set the maximum width
                        height: "auto",    // Maintain aspect ratio
                        display: "block",  // Center the image
                        margin: "0 auto",  // Center the image
                    }}
                />
                <p>We will review and get back to you soon</p>
                <Button className="btn custom-primary-btn w-100" onClick={handleContinue}
                    style={{
                        backgroundColor: "#EB5017",
                        color: "#fff",
                        borderColor: "#EB5017",
                    }}
                >
                    Continue
                </Button>
            </Container>
        </div>
    );
};

export default KycCompletion;
