import React from "react";
import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import { Img, Line, Text } from "../../components";
import { useNavigate } from "react-router-dom";

const Sidebar1 = (props) => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const navigate = useNavigate();

  console.log("Active Item Prop:", props.activeItem);

  const sideBarMenu = [
    {
      icon: (
        <Img
          className="h-5 w-5"
          src="images/img_iconhome_blue_gray_500.svg"
          alt="iconhome"
        />
      ),
      label: "Home",
      onClick: () => navigate("/dashboard"),
    },
    {
      icon: (
        <Img
          className="h-5 w-5"
          src="images/img_iconbox1.svg"
          alt="iconboxOne"
        />
      ),
      label: "Plans",
      onClick: () => navigate("/plans"),
    },
    {
      icon: (
        <Img
          className="h-5 mb-0.5 w-5"
          src="images/img_iconhistory.svg"
          alt="iconhistory"
        />
      ),
      label: "Transaction History",
      onClick: () => navigate("/transactionhistory"),
    },
    {
      icon: (
        <Img className="h-5 w-5" src="images/img_iconfile.svg" alt="iconfile" />
      ),
      label: "Documents",
      onClick: () => navigate("/documents"),
    },
    {
      icon: (
        <Img className="h-5 w-5" src="images/img_iconbell.svg" alt="iconbell" />
      ),
      label: "Notifications",
      onClick: () => navigate("/notifications"),
    },
  ];

  const sideBarMenu1 = [
    {
      icon: (
        <Img
          className="h-5 mb-0.5 w-5"
          src="images/img_iconsettings.svg"
          alt="iconsettings"
        />
      ),
      label: "Settings",
      onClick: () => navigate("/settings"),
    },
    {
      icon: (
        <Img
          className="h-5 w-5"
          src="images/img_iconheadset.svg"
          alt="iconheadset"
        />
      ),
      label: "Contact us",
    },
    {
      icon: (
        <Img className="h-5 w-5" src="images/img_icongift.svg" alt="icongift" />
      ),
      label: "Refer family & friends",
    },
    {
      icon: (
        <Img
          className="h-5 mb-0.5 w-5"
          src="images/img_iconsignout.svg"
          alt="iconsignout"
        />
      ),
      label: "Logout",
      onClick: () => {
        localStorage.clear();
        navigate("/");
      },
    },
  ];

  return (
    <>
      <Sidebar
        onClick={() => collapseSidebar(!collapsed)}
        className={props.className}
      >
        <Text
          className="ml-6 mr-[90px] mt-8 sm:text-2xl md:text-[26px] text-[28px] text-black-900"
          size="txtHoltwoodOneSC28Black900"
        >
          EVAS
        </Text>
        <div style={{ maxHeight: "calc(100vh - 130px)", overflowY: "auto" }}>
          <Menu
            menuItemStyles={{
              '.ps-sidebar .ps-sidebar-menu .ps-menu-item.ps-active': {
                color: "#d3251f",
                backgroundColor: "#FFECE5",
                fontWeight: "400 !important",
              },
              button: {
                padding: "11px 11px 11px 23px",
                gap: "12px",
                color: "#101828",
                fontSize: "14px",
                fontFamily: "Inter",
                borderRadius: "4px",
                [`&:hover, &.ps-active`]: {
                  color: "#d3251f",
                  fontWeight: "400 !important",
                },
              },
            }}
            className="flex flex-col items-center justify-between mt-[18px] w-full"
          >
            <div className="flex flex-col gap-[3.77px] items-center justify-start w-full">
              {sideBarMenu?.map((menu, i) => (
                <MenuItem
                  key={`sideBarMenuItem${i}`}
                  {...menu}
                  className={menu.label === props.activeItem ? 'ps-active' : ''}
                >
                  {menu.label}
                </MenuItem>
              ))}
            </div>
            <Line className="bg-blue_gray-50 h-px mt-6 w-full" />
            <div className="flex flex-col  items-center justify-end w-full">
              {sideBarMenu1?.map((menu, i) => (
                <MenuItem
                  key={`sideBarMenu1Item${i}`}
                  {...menu}
                  className={menu.label === props.activeItem ? 'ps-active' : ''}
                >
                  {menu.label}
                </MenuItem>
              ))}
            </div>
          </Menu>
        </div>
      </Sidebar>
    </>
  );
};

Sidebar1.defaultProps = {};

export default Sidebar1;
